import { SnackbarType } from '@assembly/contexts'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { getApiErrorMsg } from '@assembly/utils'
import { Box, Link, Typography } from '@mui/material'
import React from 'react'
import { GridColDef, DataGrid, GridCellParams } from '@mui/x-data-grid'
import { styled } from '@mui/material/styles'
import { capitalize } from 'lodash'
import moment from 'moment'
import { getPayoutInvoices } from '@assembly/api/contractor'

const DATE_FORMAT = 'YYYY-MM-DD'

const ColValue = styled(Typography)(() => ({
  fontSize: '14px',
  textAlign: 'center',
}))

const DetailCol = styled(Typography)(() => ({
  fontSize: '14px',
  whiteSpace: 'normal',
  wordBreak: 'break-word',
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
}))

const InvoiceLink = styled(Link)(() => ({
  fontSize: '14px',
  whiteSpace: 'normal',
  wordBreak: 'break-word',
  cursor: 'pointer',
  color: '#000',
}))

export default function ResourcePayoutInvoices() {
  const { addAlert } = useSnackbars()
  const didMount = React.useRef<boolean>(false)
  const { store, setPayoutInvoices } = useAppStore()
  const [loading, setLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    if (!didMount.current) {
      if (!store.isPayoutInvoicesLoaded) {
        getPayoutInvoicesAsync()
      } else {
        setLoading(false)
      }
      didMount.current = true
    }
  }, [])

  const getPayoutInvoicesAsync = async () => {
    try {
      setLoading(true)
      const { data } = await getPayoutInvoices()
      setPayoutInvoices(data)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      console.error(error)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const assemblyCol = (params: GridCellParams) => {
    return (
      <ColValue>
        {params.row.billedAssemblyLine.name}
        <br /> <b>Start Date:</b>{' '}
        {moment(new Date(params.row.billedAssemblyLine.startDate)).format(
          DATE_FORMAT
        )}
      </ColValue>
    )
  }

  const billableAmountCol = (params: GridCellParams) => {
    return <ColValue>$ {params.row.billableAmount}</ColValue>
  }

  const billableUnitsCol = (params: GridCellParams) => {
    return <ColValue>{params.row.billableUnits}</ColValue>
  }

  const statusCol = (params: GridCellParams) => {
    return <ColValue>{capitalize(params.row.status)}</ColValue>
  }

  const dateCol = (params: GridCellParams) => {
    const formattedDate = moment(params.row[params.field], 'YYYYMMDD').format(
      DATE_FORMAT
    )
    return <ColValue>{formattedDate}</ColValue>
  }

  const detailCol = (params: GridCellParams) => {
    return (
      <DetailCol>{params.row.details ? params.row.details : '---'}</DetailCol>
    )
  }

  const statusMessageCol = (params: GridCellParams) => {
    return (
      <DetailCol>
        {params.row.statusMessage ? params.row.statusMessage : '---'}
      </DetailCol>
    )
  }

  const renderInvoiceLink = (params: GridCellParams) => {
    if (!params.row.invoiceLink) {
      return <ColValue>---</ColValue>
    }

    return (
      <InvoiceLink
        href={params.row.invoiceLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        View Invoice
      </InvoiceLink>
    )
  }

  const timestampCol = (params: GridCellParams) => {
    const formatted = moment(params.row.timestamp).format('MM-DD-YYYY')
    return <ColValue>{formatted}</ColValue>
  }

  const transferIDCol = (params: GridCellParams) => {
    return <ColValue>{params.row.transferID}</ColValue>
  }

  const dataGridColumns: GridColDef[] = [
    {
      field: 'billingStart',
      headerName: 'Billing Start',
      width: 130,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: dateCol,
    },
    {
      field: 'billingEnd',
      headerName: 'Billing End',
      width: 130,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: dateCol,
    },

    {
      field: 'billableAmount',
      headerName: 'Amount (USD)',
      width: 122,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: billableAmountCol,
    },
    {
      field: 'billableUnits',
      headerName: 'Num Hours',
      width: 105,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: billableUnitsCol,
    },
    {
      field: 'billedAssemblyLines',
      headerName: 'Billed Assembly',
      width: 250,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: true,
      renderCell: assemblyCol,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 70,
      editable: false,
      sortable: false,
      disableReorder: true,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: statusCol,
    },
    {
      field: 'statusMessage',
      headerName: 'Note',
      width: 350,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: statusMessageCol,
    },

    {
      field: 'Last Updated',
      headerName: 'Last Updated',
      width: 120,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: timestampCol,
    },
    {
      field: 'invoiceLink',
      headerName: 'Invoice Link',
      width: 350,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: renderInvoiceLink,
    },
    {
      field: 'details',
      headerName: 'Details',
      width: 200,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: detailCol,
    },
  ]

  return (
    <Box>
      <DataGrid
        columns={dataGridColumns}
        rows={store.payoutInvoices}
        loading={loading}
        sx={{ height: 'calc(100vh - 180px)', border: 0 }}
        getRowId={() => Math.random().toString()}
        hideFooter
        showCellVerticalBorder
      />
    </Box>
  )
}
