import * as React from 'react'
import Button from '@mui/lab/LoadingButton'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
} from '@mui/material'
import { useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import validator from 'validator'
import {
  dialogOpenCloseTransitionDuration,
  getApiErrorMsg,
} from '@assembly/utils'
import { styled } from '@mui/material/styles'
import { publicProfileContact } from '@assembly/api/contractor'
import { contactFormAssembly } from '@assembly/api/assemblyTemplates'

const StyledTextField = styled(TextField)(() => ({
  margin: 0,
  marginBottom: '20px',
}))

interface WorkWithMeDialogTitleProps {
  children?: React.ReactNode
  onClose: () => void
}

function WorkWithMeDialogTitle(props: WorkWithMeDialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface WorkWithMeDialogProps {
  open: boolean
  onClose: () => void
  id: string
  isAssembly?: boolean
}

export default function WorkWithMeDialog(props: WorkWithMeDialogProps) {
  const { open, onClose, id, isAssembly } = props
  const formInitialValues = {
    description: '',
    monthlyBudget: '',
    contactEmail: '',
    invalid_contactEmail: false,
  }
  const [formValues, setFormValues] = React.useState(formInitialValues)
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const { addAlert } = useSnackbars()

  const handleChangeFormField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleClickSave = async () => {
    if (
      formValues.contactEmail.length === 0 ||
      !validator.isEmail(formValues.contactEmail)
    ) {
      setFormValues({
        ...formValues,
        invalid_contactEmail: true,
      })
      return
    }

    try {
      setIsSaving(true)

      if (isAssembly) {
        const payload = {
          description: formValues.description,
          monthly_budget: formValues.monthlyBudget,
          contactEmail: formValues.contactEmail,
        }
        await contactFormAssembly(id, payload)
      } else {
        const payload = new URLSearchParams()
        payload.append('description', formValues.description)
        payload.append('monthly_budget', formValues.monthlyBudget)
        payload.append('contactEmail', formValues.contactEmail)
        await publicProfileContact(id, payload)
      }
      setIsSaving(true)
      setIsSaving(false)
      handleClose()
    } catch (error: any) {
      setIsSaving(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const handleClose = () => {
    setTimeout(() => {
      setFormValues(formInitialValues)
    }, dialogOpenCloseTransitionDuration)
    setIsSaving(false)
    onClose()
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="sm"
        fullWidth
        transitionDuration={dialogOpenCloseTransitionDuration}
      >
        <WorkWithMeDialogTitle onClose={handleClose}>
          Please fill in the following information
        </WorkWithMeDialogTitle>
        <DialogContent dividers={true}>
          <StyledTextField
            value={formValues.contactEmail}
            margin="dense"
            label="Contact Email"
            type="text"
            name="contactEmail"
            fullWidth
            variant="outlined"
            onChange={handleChangeFormField}
            error={formValues.invalid_contactEmail}
            helperText={
              formValues.invalid_contactEmail
                ? formValues.contactEmail.length === 0
                  ? 'Required'
                  : 'Invalid email'
                : ''
            }
          />
          <StyledTextField
            value={formValues.description || ''}
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            onChange={handleChangeFormField}
            multiline
            rows={5}
          />
          <StyledTextField
            value={formValues.monthlyBudget || ''}
            margin="dense"
            label="Monthly Budget (in $ USD)"
            type="number"
            name="monthlyBudget"
            fullWidth
            variant="outlined"
            onChange={handleChangeFormField}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button loading={isSaving} onClick={handleClickSave}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
