import { Box, Typography, Tab, Tabs } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { UserCard } from './UserCard'
import { PricingCard } from './PricingCard'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { AssemblyTemplate, Contractor } from '@assembly/types'
import { Resource } from '@assembly/types/resource'
import { ContractorPublicProfile } from '@assembly/components'
import HireAssemblyDialog from '@assembly/components/ContractorPublicProfile/WorkWithMeDialog'

const TabItem = styled(Tab)(({ theme }) => ({
  fontFamily: 'Space Grotesk',
  fontSize: '16px',
  textTransform: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
  },
}))

const TabsBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: '35px',
  [theme.breakpoints.down('lg')]: {
    marginTop: '25px',
  },
}))

const TabContentBox = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: '42px',
  border: '3px solid #EFEFEF',
  background: '#FFF',
  boxShadow: '0px 14px 34px -10px rgba(0, 0, 0, 0.10)',
  [theme.breakpoints.down('md')]: {
    borderRadius: '34px',
  },
}))

const TabContentTitle = styled(Typography)(({ theme }) => ({
  fontSize: '30px',
  fontFamily: 'Space Grotesk',
  fontWeight: 600,
  marginTop: '17px',
  marginBottom: '10px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '22px',
    marginTop: '14px',
    marginBottom: '6px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
  },
}))

const TabContentHeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: '3px solid #EFEFEF',
  paddingLeft: '23px',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}))

const TabContentPriceItemBox = styled(Box)(({ theme }) => ({
  borderRadius: '24.461px',
  border: '0.349px solid #E3E3E3',
  background: '#FFF',
  boxShadow:
    '0px 1.3977792263031006px 18.870019912719727px 0px rgba(0, 0, 0, 0.10)',
  width: '20%',
  minHeight: '390px',
  paddingLeft: '23px',
  paddingRight: '23px',
  boxSizing: 'border-box',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}))

const TabContentPriceItemBoxSmall = styled(Box)(({ theme }) => ({
  borderRadius: '24.461px',
  border: '0.349px solid #E3E3E3',
  background: '#FFF',
  boxShadow:
    '0px 1.3977792263031006px 18.870019912719727px 0px rgba(0, 0, 0, 0.10)',
  marginBottom: '20px',
  marginLeft: '20px',
  marginRight: '20px',
  padding: '15px',
  boxSizing: 'border-box',
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: '12px',
    marginLeft: '12px',
    marginRight: '12px',
  },
}))

const TabContentPersonItemBox = styled(Box)(({ theme }) => ({
  borderRadius: '25px',
  border: '0.349px solid #E3E3E3',
  background: '#FFF',
  flexBasis: 'calc(25% - 13px)',
  flexGrow: 0,
  flexShrink: 0,
  overflow: 'auto',
  boxSizing: 'border-box',
  [theme.breakpoints.down('lg')]: {
    flexBasis: 'calc(33.333% - 13px)',
  },
  [theme.breakpoints.down('md')]: {
    flexBasis: 'calc(50% - 13px)',
  },
  [theme.breakpoints.down('sm')]: {
    flexBasis: 'calc(50% - 6px)',
  },
}))

const TabContentItemsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingTop: '20px',
  paddingBottom: '20px',
  paddingLeft: '20px',
  paddingRight: '20px',
  gap: '17px',
  [theme.breakpoints.down('sm')]: {
    gap: '11px',
    paddingLeft: '12px',
    paddingRight: '12px',
    paddingTop: '12px',
    paddingBottom: '12px',
  },
}))

const CustomTabPanelBox = styled(Box)(({ theme }) => ({
  padding: '24px',
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    paddingTop: '20px',
  },
}))

const PersonsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '17px',
  width: '80%',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    gap: '11px',
  },
}))

function a11yProps(index: number) {
  return {
    id: `people-tab-${index}`,
    'aria-controls': `people-tabpanel-${index}`,
  }
}

export default function AssemblyTemplates({
  assemblyTemplates,
  hireDialogOpen,
  onCloseHireDialog,
}: {
  assemblyTemplates: AssemblyTemplate[]
  hireDialogOpen: boolean
  onCloseHireDialog: () => void
}) {
  const [value, setValue] = React.useState(0)
  const theme = useTheme()
  const isSM = useMediaQuery(theme.breakpoints.down('sm'))
  const [selectedResource, setSelectedResource] =
    React.useState<Resource | null>()
  const profileDivRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (
      assemblyTemplates &&
      assemblyTemplates[value] &&
      assemblyTemplates[value].allocations[0]
    ) {
      setSelectedResource(assemblyTemplates[value].allocations[0].resource)
    } else {
      setSelectedResource(null)
    }
  }, [value])

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
  }

  const handleClickResource = (resource: Resource) => {
    setSelectedResource(resource)
    setTimeout(() => {
      profileDivRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, 200)
  }

  return (
    <Box>
      <TabsBox>
        <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
          <Tabs
            orientation={isSM ? 'vertical' : 'horizontal'}
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            TabScrollButtonProps={{
              slots: {
                EndScrollButtonIcon: () => <ArrowForwardIcon color="primary" />,
                StartScrollButtonIcon: () => <ArrowBackIcon color="primary" />,
              },
            }}
            sx={{ alignItems: 'center' }}
          >
            {assemblyTemplates.map((item, index) => (
              <TabItem
                key={index}
                label={item.name}
                sx={{
                  fontWeight: value === index ? 600 : 300,
                  color: value === index ? 'primary' : '#868686',
                }}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>
        <CustomTabPanelBox>
          <TabContentBox>
            <TabContentHeaderBox>
              <TabContentTitle>{assemblyTemplates[value].name}</TabContentTitle>
            </TabContentHeaderBox>
            <TabContentItemsBox>
              <TabContentPriceItemBox>
                <PricingCard
                  assemblyAmount={
                    assemblyTemplates[value].approximateMonthlyBudget
                  }
                  noOfExperts={assemblyTemplates[value].allocations.length}
                  description={assemblyTemplates[value].description}
                />
              </TabContentPriceItemBox>
              <PersonsBox>
                {assemblyTemplates[value].allocations.map((person, index) => (
                  <TabContentPersonItemBox
                    key={index}
                    sx={{
                      borderWidth:
                        selectedResource?.id === person.resource?.id
                          ? '2px'
                          : '0px',
                      borderColor: 'primary.main',
                      borderStyle: 'solid',
                    }}
                  >
                    <UserCard
                      key={index}
                      person={person}
                      onClick={handleClickResource}
                    />
                  </TabContentPersonItemBox>
                ))}
              </PersonsBox>
            </TabContentItemsBox>
            <TabContentPriceItemBoxSmall>
              <PricingCard
                assemblyAmount={
                  assemblyTemplates[value].approximateMonthlyBudget
                }
                noOfExperts={assemblyTemplates[value].allocations.length}
                description={assemblyTemplates[value].description}
              />
            </TabContentPriceItemBoxSmall>
          </TabContentBox>
        </CustomTabPanelBox>
      </TabsBox>
      <div ref={profileDivRef}>
        <ContractorPublicProfile data={selectedResource as Contractor} />
      </div>
      <HireAssemblyDialog
        open={hireDialogOpen}
        onClose={onCloseHireDialog}
        id={assemblyTemplates[value].id}
        isAssembly={true}
      />
    </Box>
  )
}
