import { formatNumbersWithCommas } from '@assembly/utils'
import { Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const TabContentPriceItemTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '30px',
  fontWeight: 600,
  fontFamily: 'Space Grotesk',
  marginTop: '28px',
  marginBottom: '5px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '25px',
    marginBottom: '2px',
    marginTop: 0,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '19.839px',
  },
}))

const TabContentPriceItemSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontFamily: 'Space Grotesk',
  fontWeight: 400,
  lineHeight: '26px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '17px',
    lineHeight: '22px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '13.226px',
    lineHeight: '20px',
  },
}))

const AssemblyDescription = styled(Typography)(() => ({
  fontSize: '16px',
  fontFamily: 'Space Grotesk',
  marginTop: '20px',
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 8,
}))

export const PricingCard = ({
  assemblyAmount,
  noOfExperts,
  description,
}: any) => {
  return (
    <Box>
      <Box>
        <TabContentPriceItemTitle>Assembly</TabContentPriceItemTitle>
        <TabContentPriceItemSubtitle>
          ${formatNumbersWithCommas(assemblyAmount)}/Month
        </TabContentPriceItemSubtitle>
        <TabContentPriceItemSubtitle>
          Experts: {noOfExperts}
        </TabContentPriceItemSubtitle>
        <TabContentPriceItemSubtitle>
          Month to Month
        </TabContentPriceItemSubtitle>
      </Box>
      <Box>
        <AssemblyDescription>{description}</AssemblyDescription>
      </Box>
    </Box>
  )
}
