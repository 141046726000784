import {
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
  colors,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { getContract, getProfile } from '@assembly/api/contractor'
import SignContractDialog from './SignContractDialog'
import { getApiErrorMsg, getMediaUrl } from '@assembly/utils'
import { SnackbarType } from '@assembly/contexts'
import jsPDF from 'jspdf'
import logo from '@assembly/assets/logo.png'
import axios from 'axios'
import DownloadIcon from '@mui/icons-material/Download'

const StyledPaper = styled(Paper)(() => ({
  padding: '0 15px 20px 15px',
  border: '1px solid',
  borderColor: colors.grey[200],
  borderRadius: '10px',
  boxShadow: 'none',
}))

const AccepButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
}))

const SignatureImg = styled('img')(() => ({
  width: '100px',
  height: 'auto',
  marginBottom: '5px',
}))

const SignatureOuterBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  paddingRight: '300px',
}))

const SignatureBox = styled(Box)(() => ({
  display: 'flex'
}))

const SignatureLabel = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 500,
}))

const DownloadAsPDFButton = styled(Button)(() => ({
  marginBottom: '20px',
  display: 'flex',
  alignItems: 'center',
}))

const StyledDownloadIcon = styled(DownloadIcon)(() => ({
  marginRight: '5px',
  width: '20px',
  height: '20px',
}))

const Logo = styled('img')(() => ({
  width: '150px',
  height: 'auto',
  marginTop: '20px',
}))

export default function Contract() {
  const didMountRef = React.useRef(false)
  const [loading, setIsLoading] = React.useState<boolean>(false)
  const { store, setContractorProfile, setContract, setSignatureBase64 } =
    useAppStore()
  const [signContractDialogOpen, setSignContractDialogOpen] =
    React.useState<boolean>(false)
  const [contractIsSigned, setContractIsSigned] =
      React.useState<boolean>(false)
  const { addAlert } = useSnackbars()

  React.useEffect(() => {
    if (!didMountRef.current) {
      if (!store.isContractorProfileLoaded || !store.isContractLoaded) {
        getProfileAsync()
      } else {
        setIsLoading(false)
      }
      didMountRef.current = true
    }
  }, [])

  const getProfileAsync = async () => {
    try {
      setIsLoading(true)
      const { data } = await getProfile()
      const res = await getContract()
      if (data.contractSignature && data.contractSignature.media) {
        const base64Data = await fetchImageAndConvertToBase64(
          getMediaUrl(data.contractSignature.media) as string
        )
        setSignatureBase64(base64Data)
      }
      setContract(res.data.message)
      setContractorProfile(data)
      setContractIsSigned(data.hasSignedContract)
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const handleClickAccept = () => {
    setSignContractDialogOpen(true)
  }

  const handleCloseSignContractDialog = () => {
    setSignContractDialogOpen(false)
  }

  const handleSuccess = (data: any) => {
    setContractorProfile(data)
  }

  const fetchImageAndConvertToBase64 = async (url: string) => {
    try {
      const response = await axios.get(url, { responseType: 'blob' })

      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          const base64data = reader.result
          resolve(base64data)
        }
        reader.onerror = () => {
          reject(new Error('Failed to convert blob to base64'))
        }
        reader.readAsDataURL(response.data)
      })
    } catch (error: any) {
      console.error('There was a problem with the fetch operation:', error)
    }
  }

  const generatePDF = () => {
    const report = new jsPDF('portrait', 'pt', 'a3')

    // Signature Image Loading from base64
    const signatureImg = new Image()
    signatureImg.src = store.signatureBase64
    signatureImg.style.width = '100px'
    signatureImg.style.height = 'auto'

    // Creating main div
    const el = document.createElement('div')
    el.innerHTML = store.contract
    el.style.letterSpacing = '1px'
    el.style.width = '720px'

    // Creating signature div containning label and image
    const signatureBox = document.createElement('div')
    signatureBox.style.width = '100%'
    signatureBox.style.display = 'flex'
    signatureBox.style.justifyContent = 'flex-end'
    signatureBox.style.paddingRight = '250px'

    // Creating inner div for signature
    const signatureInnerBox = document.createElement('div')
    signatureInnerBox.style.display = 'flex'

    // Appending image and label to inner div
    signatureInnerBox.appendChild(signatureImg)

    // Appending inner div to signature div
    signatureBox.appendChild(signatureInnerBox)

    // Appending signature div to main div
    el.appendChild(signatureBox)

    const img = document.createElement('img')
    img.src = logo

    report.addImage(img as any, 'PNG', 60, 30, 120, 33)

    report.html(el as any, {
      x: 0,
      y: 0,
      margin: [60, 60, 78, 60],
      autoPaging: 'text',
      callback: function (pdf) {
        pdf.save('Assembly Industries Contract.pdf')
      },
    })
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return contractIsSigned === true
         ?
            <Box display="flex" justifyContent="center"><h3>Contract Signed</h3></Box> :
            <Box>
              <DownloadAsPDFButton variant="outlined" onClick={generatePDF}>
                <StyledDownloadIcon/>
                <span>Download as PDF</span>
              </DownloadAsPDFButton>
              <StyledPaper>
                {store.contract && (
                    <React.Fragment>
                      <Logo src={logo} alt="logo"/>
                      <div dangerouslySetInnerHTML={{__html: store.contract}}/>
                    </React.Fragment>
                )}
                {store.contractorProfile.hasSignedContract === false ? (
                    <AccepButtonBox>
                      <Button variant="contained" onClick={handleClickAccept}>
                        Accept
                      </Button>
                    </AccepButtonBox>
                ) : (
                    <SignatureOuterBox>
                      <SignatureBox>
                        <SignatureImg
                            src={getMediaUrl(
                                store.contractorProfile.contractSignature?.media || ''
                            )}
                        />
                      </SignatureBox>
                    </SignatureOuterBox>
                )}
              </StyledPaper>
              <SignContractDialog
                  open={signContractDialogOpen}
                  onClose={handleCloseSignContractDialog}
                  onSuccess={handleSuccess}
              />
            </Box>
}
