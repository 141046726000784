import { Media, Capability, Skill } from '@assembly/types'

export type Contractor = {
  id: string
  label: string
  email: string
  assemblyEmail: string
  phone: string
  status: string
  firstName: string
  lastName: string
  payoutCurrency: string
  profileLink: string
  videoInterviewLink: string
  introLink: string
  capabilities: Capability[]
  bio: string
  desiredWeeklyHours: number | null
  desiredHourlyRate: number | null
  availableWeeklyHours: number | null
  paymentCountry: string
  profilePicture: Media | null
  availableStartDate: string
  hasSignedContract?: boolean
  contractSignature?: Media | null
  highlights?: Highlight[]
  portfolio?: Portfolio[]
  skills?: any[]
  introVideo?: Media | null
  minNoticePeriodDays?: number | null
  w8BEN?: Media | null
}

export type ContractorFormValuesType = {
  label: string
  email: string
  assemblyEmail: string
  phone: string
  firstName: string
  lastName: string
  payoutCurrency: string
  profileLink: string
  videoInterviewLink: string
  minNoticePeriodDays: number | null
  capabilities: Capability[]
  skills: Skill[]
  portfolio: Portfolio[]
  bio: string
  availableStartDate: any
  desiredWeeklyHours: number | null
  desiredHourlyRate: number | null
  availableWeeklyHours: number | null
  paymentCountry: string
  profilePicture: Media | null
  w8BEN: Media | null
  invalid_label: boolean
  invalid_email: boolean
  invalid_firstName: boolean
  invalid_lastName: boolean
  invalid_phone: boolean
  invalid_availableStartDate: boolean
  invalid_assemblyEmail: boolean
  invalid_profileLink: boolean
  invalid_minNoticePeriodDays: boolean
  invalid_profilePicture: boolean
  invalid_portfolio: boolean,
  invalid_w8BEN: boolean
}


export enum HighlightType {
  Url = 'URL',
  Text = 'TEXT',
  Media = 'MEDIA'
}

export type Highlight = {
  type: HighlightType,
  title: string,
  question: string,
  description: string,
  answerUrl: string | null,
  answerText: string | null,
  answerMedia: Media | null,
}

export enum PortfolioType {
  Media = 'MEDIA',
  Url = 'URL'
}

export type Portfolio = {
  type: PortfolioType,
  description: string,
  title: string,
  url: string | null,
  media: Media | null,
}

