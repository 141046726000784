import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import logo from '@assembly/assets/logo.png'

const Logo = styled('img')(() => ({
  width: '150px',
  height: 'auto',
}))

const StyledToolbar = styled(Toolbar)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const HireThisTeamButton = styled(Button)(() => ({
  boxShadow: 'none',
  textTransform: 'none',
}))

type HeaderProps = {
  onClickHireButton: () => void
}

function Header({ onClickHireButton }: HeaderProps) {
  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        backgroundColor: '#fff',
        color: '#000',
        borderBottom: '1px solid #eceefe',
      }}
    >
      <Container maxWidth="xl">
        <StyledToolbar>
          <Button href="https://assembly-industries.com">
            <Logo src={logo} alt="" />
          </Button>
          <HireThisTeamButton variant="contained" onClick={onClickHireButton}>
            Hire This Team
          </HireThisTeamButton>
        </StyledToolbar>
      </Container>
    </AppBar>
  )
}
export default Header
