import { setupPaymentAccount } from '@assembly/api/contractor'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'
import { LinkButton } from '@assembly/components'
import { useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import { getApiErrorMsg } from '@assembly/utils'

const SectionBox = styled(Box)(() => ({
  background: '#FFFFFF',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '10px',
}))

export default function PayoutSetup() {
  const [loading, setLoading] = React.useState(false)
  const { addAlert } = useSnackbars()

  const handleClickSetupPaymentAccount = async () => {
    try {
      setLoading(true)
      const { data } = await setupPaymentAccount()
      window.open(data.message, '_blank')
      setLoading(false)
    } catch (error: any) {
      console.error(error)
      setLoading(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Info,
      })
    }
  }

  return (
    <Box>
      <SectionBox>
        <Typography variant="h5" fontWeight={500}>
          Setup Payment Account
        </Typography>
        <LinkButton
          loading={loading}
          label="Setup"
          variant="contained"
          onClick={handleClickSetupPaymentAccount}
        />
      </SectionBox>
    </Box>
  )
}
