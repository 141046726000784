import {
  Assembly,
  Contractor,
  PayoutEntry,
  PayoutInvoice,
} from '@assembly/types'
import { createContext, useState, useMemo, useCallback } from 'react'

type Store = {
  appBarTitle: string
  contractorProfile: Contractor
  isContractorProfileLoaded: boolean
  assemblies: Assembly[]
  isAssembliesLoaded: boolean
  payoutEntries: PayoutEntry[]
  isPayoutEntriesLoaded: boolean
  payoutInvoices: PayoutInvoice[]
  isPayoutInvoicesLoaded: boolean
  contract: string
  isContractLoaded: boolean
  signatureBase64: any
}

interface IAppContext {
  store: Store
  setAppBarTitle: (title: string) => void
  setAssemblies: (assemblies: Assembly[]) => void
  setContractorProfile: (profile: Contractor) => void
  setPayoutEntries: (payoutEntries: PayoutEntry[]) => void
  setPayoutInvoices: (payoutInvoices: PayoutInvoice[]) => void
  setContract: (contract: string) => void
  setSignatureBase64: (signatureBase64: any) => void
}

export const AppContext = createContext<IAppContext>({
  store: {
    appBarTitle: '',
    assemblies: [],
    isAssembliesLoaded: false,
    contractorProfile: {} as Contractor,
    isContractorProfileLoaded: false,
    payoutEntries: [],
    isPayoutEntriesLoaded: false,
    payoutInvoices: [],
    isPayoutInvoicesLoaded: false,
    contract: '',
    isContractLoaded: false,
    signatureBase64: null,
  },
  setAppBarTitle: (title: string) => {},
  setAssemblies: (assemblies: Assembly[]) => {},
  setContractorProfile: (profile: Contractor) => {},
  setPayoutEntries: (payoutEntries: PayoutEntry[]) => {},
  setPayoutInvoices: (payoutInvoices: PayoutInvoice[]) => {},
  setContract: (contract: string) => {},
  setSignatureBase64: (signatureBase64: any) => {},
})

interface AppProviderProps {
  children?: React.ReactNode
}

export function AppProvider({ children }: AppProviderProps) {
  const [store, setStore] = useState<Store>({
    appBarTitle: '',
    assemblies: [],
    isAssembliesLoaded: false,
    contractorProfile: {} as Contractor,
    isContractorProfileLoaded: false,
    payoutEntries: [],
    isPayoutEntriesLoaded: false,
    payoutInvoices: [],
    isPayoutInvoicesLoaded: false,
    contract: '',
    isContractLoaded: false,
    signatureBase64: null,
  })

  const setAppBarTitle = useCallback((title: string) => {
    setStore((store) => ({
      ...store,
      appBarTitle: title,
    }))
  }, [])

  const setAssemblies = useCallback((assemblies: Assembly[]) => {
    setStore((store) => ({
      ...store,
      assemblies,
      isAssembliesLoaded: true,
    }))
  }, [])

  const setContractorProfile = useCallback((profile: Contractor) => {
    setStore((store) => ({
      ...store,
      contractorProfile: profile,
      isContractorProfileLoaded: true,
    }))
  }, [])

  const setPayoutEntries = useCallback((payoutEntries: PayoutEntry[]) => {
    setStore((store) => ({
      ...store,
      payoutEntries,
      isPayoutEntriesLoaded: true,
    }))
  }, [])

  const setPayoutInvoices = useCallback((payoutInvoices: PayoutInvoice[]) => {
    setStore((store) => ({
      ...store,
      payoutInvoices,
      isPayoutInvoicesLoaded: true,
    }))
  }, [])

  const setContract = useCallback((contract: string) => {
    setStore((store) => ({
      ...store,
      contract,
      isContractLoaded: true,
    }))
  }, [])

  const setSignatureBase64 = useCallback((signatureBase64: any) => {
    setStore((store) => ({
      ...store,
      signatureBase64,
    }))
  }, [])

  const value = useMemo(
    () => ({
      store,
      setAppBarTitle,
      setAssemblies,
      setContractorProfile,
      setPayoutEntries,
      setPayoutInvoices,
      setContract,
      setSignatureBase64,
    }),
    [
      store,
      setAppBarTitle,
      setAssemblies,
      setContractorProfile,
      setPayoutEntries,
      setPayoutInvoices,
      setContract,
      setSignatureBase64,
    ]
  )

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
