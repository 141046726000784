import React from 'react'
import { getAssemblyTemplate } from '@assembly/api/assemblyTemplates'
import { Box, CircularProgress, Container, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import AssemblyTemplates from './Tabs'
import { styled } from '@mui/material/styles'
import Header from './Header'
import { AssemblyTemplate as IAssemblyTemplate } from '@assembly/types'

const LoadingBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
})

export default function AssemblyTemplate() {
  const [hireDialogOpen, setHireDialogOpen] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [assemblyTemplates, setAssemblyTemplates] = React.useState<
    IAssemblyTemplate[]
  >([])
  const didMountRef = React.useRef<boolean>(false)
  const params = useParams()
  const ids = params.id ? params.id.split(',') : []

  React.useEffect(() => {
    if (!didMountRef.current) {
      getAssemblyTemplateAsync()
      didMountRef.current = true
    }
  }, [])

  const getAssemblyTemplateAsync = async () => {
    try {
      setIsLoading(true)
      const promises = ids.map((id) => getAssemblyTemplate(id))
      const results = await Promise.all(promises)
      const data = results
        .map((result) => result.data)
        .filter((item) => typeof item === 'object')
      setAssemblyTemplates(data)
      setIsLoading(false)
    } catch (error: any) {
      console.error(error)
      setIsLoading(false)
    }
  }

  const handleClickHireButton = () => {
    setHireDialogOpen(true)
  }

  const handleCloseHireDialog = () => {
    setHireDialogOpen(false)
  }

  if (isLoading) {
    return (
      <LoadingBox>
        <CircularProgress />
      </LoadingBox>
    )
  }

  if (!isLoading && assemblyTemplates.length === 0) {
    return (
      <LoadingBox>
        <Typography>No Data Found</Typography>
      </LoadingBox>
    )
  }

  return (
    <Box>
      <Header onClickHireButton={handleClickHireButton} />
      <Container maxWidth="xl">
        <AssemblyTemplates
          assemblyTemplates={assemblyTemplates}
          hireDialogOpen={hireDialogOpen}
          onCloseHireDialog={handleCloseHireDialog}
        />
      </Container>
    </Box>
  )
}
