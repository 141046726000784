import { Capability } from "./capability"
import { Resource } from "./resource"

export type Assembly = {
  assemblyID: string
  assemblyName: string
  contractDetails: string
  assemblyProjectManagementLink: string
  assemblyStartDate: string
  assemblyStatus: string
  allocationStatus: string
  sowLink: string
}

export type AssemblyTemplate = {
  id: string
  name: string
  description: string
  capabilities: Capability[]
  approximateMonthlyBudget: number | null
  allocations: Allocation[]
}

export type Allocation = {
  resourceID: string
  resource?: Resource
  amount: number | null
  rate: number | null
  rateUnit: RateUnit
  frequency: Frequency
  status: Status
  statusMessage: string
  approved: boolean
  approvedBy: string
  active: boolean
  type: Type
}

export enum RateUnit {
  Hours = 'HOURS',
  Calls = 'CALLS',
  Metric = 'METRIC',
  Imperial = 'IMPERIAL',
}

export enum Frequency {
  Weekly = 'WEEKLY',
  BiWeekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY',
  Project = 'PROJECT',
}

export enum Status {
  Initialized = 'INITIALIZED',
  Proposal = 'PROPOSAL',
  Provisioning = 'PROVISIONING',
  Ready = 'READY',
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  Archived = 'ARCHIVED',
}

export enum AllocationStatus {
  Approved = 'APPROVED',
  Ready = 'READY'
}

export enum Type {
  Contractor = 'CONTRACTOR',
  API = 'API',
  Service = 'SERVICE',
}