import React from 'react'

export default function PartnerTraining() {
  return (
    <React.Fragment>
      <DocumentViewer />
    </React.Fragment>
  )
}

const DocumentViewer = () => {
  return (
    <div style={{ height: '85vh' }}>
      <iframe
        style={{ border: 'none' }}
        width="100%"
        height="100%"
        src="https://docs.google.com/document/u/2/d/e/2PACX-1vTvlDs8oSA3iPisGKbpLZFCvwxtdmawtrf14Z38rAUAr_duCE9XmG_5JM2xX5FYvhjmWmlBobz2GQFJ/pub"
      ></iframe>
    </div>
  )
}
