export enum RoutePaths {
  Root = '/',
  ContractorProfile = '/profile',
  ContractorProfilePublic = '/talent/:id',
  ContractorAssemblies = '/assemblies',
  ContractorPayoutSetup = '/payout/setup',
  ContractorPayoutEntries = '/payout/entries',
  ContractorPayoutInvoices = '/payout/invoices',
  AssemblyPublic = '/assembly/:id/public',
  Contract = '/contract',
  FAQ = '/faq',
  PartnerOnboarding = '/partner-onboarding',
  PartnerTraining = '/partner-training',
  TimeOff = '/time-off',
}
