import React from 'react'

export default function FAQ() {
  return (
    <React.Fragment>
      <DocumentViewer />
    </React.Fragment>
  )
}

const DocumentViewer = () => {
  return (
    <div style={{ height: '85vh' }}>
      <iframe
        style={{ border: 'none' }}
        width="100%"
        height="100%"
        src="https://v2-embednotion.com/Assembly-Partner-Onboarding-Guide-aef52849d3304c478ae7c402ada3e971"
      ></iframe>
    </div>
  )
}
