import { axiosWithConfig } from '@assembly/config/axios'

export const uploadMedia = (payload: any, onProgressChange?: any) => {
  return axiosWithConfig.put(`/media/contractor/dashboard/setup`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (progressEvent: any) => {
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (onProgressChange) onProgressChange(percentCompleted)
    },
  })
}

export const uploadPrivateFile = (payload: any, onProgressChange?: any) => {
  return axiosWithConfig.put(`/media/private/resource`, payload, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (progressEvent: any) => {
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (onProgressChange) onProgressChange(percentCompleted)
    },
  })
}