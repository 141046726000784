import {
  Box,
  CircularProgress,
  Container,
  LinearProgress,
  Typography,
} from '@mui/material'
import React from 'react'
import 'react-notion/src/styles.css'
import 'prismjs/themes/prism-tomorrow.css'
import logo from '@assembly/assets/logo.png'
import { styled } from '@mui/material/styles'
import QuestionDialog from './QuestionDialog'
import { getProfile, onboadingComplete } from '@assembly/api/contractor'
import { useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { getApiErrorMsg } from '@assembly/utils'
import { RoutePaths } from '@assembly/constants'

const HeaderBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '20px',
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 100,
  maxWidth: '900px',
  left: '50%',
  transform: 'translate(-50%, 0)',
  backgroundColor: 'white',
  paddingBottom: '20px',
}))

const Logo = styled('img')(() => ({
  width: '170px',
  height: 'auto',
}))

const StyledLinearProgress = styled(LinearProgress)(() => ({
  marginTop: '25px',
  height: '5px',
  borderRadius: '5px',
  width: '100%',
}))

const BottomActionBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
}))

const StyledButton = styled(LoadingButton)(() => ({
  boxShadow: 'none',
}))

const Title = styled(Typography)(() => ({
  fontWeight: 500,
}))

const MainBox = styled(Box)(() => ({
  paddingTop: '135px',
  paddingBottom: '50px',
  boxSizing: 'border-box',
  height: '100vh',
}))

const ActivityIndicatorBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
}))

export default function Onboarding() {
  const [progress, setProgress] = React.useState(0)
  const [questionDialogOpen, setQuestionDialogOpen] =
    React.useState<boolean>(false)
  const [onboardingData, setOnboardingData] = React.useState<any>(null)
  const [currentIndex, setCurrentIndex] = React.useState<number>(0)
  const didMountRef = React.useRef<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const { addAlert } = useSnackbars()
  const [onboardingCompleteLoading, setOnboardingCompleteLoading] =
    React.useState<boolean>(false)

  React.useEffect(() => {
    if (!didMountRef.current) {
      getOnboardingContent()

      didMountRef.current = true
    }
  }, [])

  const getOnboardingContent = async () => {
    try {
      setIsLoading(true)
      const { data } = await getProfile()
      setOnboardingData(data.onboardingPages)
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
      setIsLoading(false)
    }
  }

  const handleClickNext = () => {
    setQuestionDialogOpen(true)
  }

  const handleSuccess = () => {
    if (currentIndex < onboardingData.length - 1) {
      setCurrentIndex(currentIndex + 1)
    } else {
      addAlert({
        type: SnackbarType.Success,
        message:
          'Congrats! You have completed the onboarding process. You will be redirected to the dashboard page in few seconds.',
      })
      completeOnboardingAsync()
    }
    const newProgress = progress + 100 / onboardingData.length
    setProgress(newProgress)
  }

  const completeOnboardingAsync = async () => {
    try {
      setOnboardingCompleteLoading(true)
      await onboadingComplete()
      setOnboardingCompleteLoading(false)
      window.location.href = RoutePaths.Root
    } catch (error: any) {
      addAlert({
        type: SnackbarType.Error,
        message: getApiErrorMsg(error),
      })
      setOnboardingCompleteLoading(false)
    }
  }

  if (isLoading) {
    return (
      <ActivityIndicatorBox>
        <CircularProgress />
      </ActivityIndicatorBox>
    )
  }

  const currentOnboardingPage = onboardingData[currentIndex]

  return (
    <MainBox>
      <HeaderBox>
        <Logo src={logo} alt="" />
        <StyledLinearProgress variant="determinate" value={progress} />
      </HeaderBox>
      <Container maxWidth="md">
        <Title variant="h4">
          Step {currentIndex + 1} :{' '}
          {currentOnboardingPage.title || 'Placeholder Title'}
        </Title>
        <OnboardingPage pageLink={currentOnboardingPage.link} />
        <BottomActionBox>
          <StyledButton
            loading={onboardingCompleteLoading}
            variant="contained"
            onClick={handleClickNext}
          >
            Next
          </StyledButton>
        </BottomActionBox>
      </Container>
      <QuestionDialog
        open={questionDialogOpen}
        onClose={() => setQuestionDialogOpen(false)}
        onSuccess={handleSuccess}
        challengeQuestions={currentOnboardingPage.challengeQuestions}
      />
    </MainBox>
  )
}

const OnboardingPage = ({ pageLink }: { pageLink: string }) => {
  return (
    <React.Fragment>
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 290px)',
          marginTop: '20px',
          marginBottom: '30px',
        }}
      >
        <iframe
          style={{ border: 'none' }}
          width="100%"
          height="100%"
          src={pageLink}
        ></iframe>
      </div>
    </React.Fragment>
  )
}
