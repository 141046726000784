import { axiosWithConfig, axiosWithConfigForAuth } from '@assembly/config/axios'

export const getOneTimeCode = async (params: any) => {
  return axiosWithConfigForAuth.post('/auth/contractor/one-time-code', params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export const login = (payload: any) => {
  return axiosWithConfigForAuth.get(
    `/auth/contractor/login?email=${payload.email}&code=${payload.code}&ref=`
  )
}

export const getProfile = async () => {
  return axiosWithConfig.get('/contractor/dashboard/profile')
}

export const updateProfile = async (payload: any) => {
  return axiosWithConfig.post('/contractor/dashboard/profile', payload)
}

export const getAssemblies = async () => {
  return axiosWithConfig.get('/contractor/dashboard/profile/assemblies')
}

export const setupPaymentAccount = async () => {
  return axiosWithConfig.get(
    '/contractor/dashboard/profile/setup-connect-account'
  )
}

export const getPayoutEntries = async () => {
  return axiosWithConfig.get(
    '/contractor/dashboard/profile/daily/payout-entries'
  )
}

export const getPayoutInvoices = async () => {
  return axiosWithConfig.get('/contractor/dashboard/profile/payout-invoices')
}

export const checkSessionIsValid = () => {
  return axiosWithConfigForAuth.get(`/contractor/session/ping`)
}

export const getContract = async () => {
  return axiosWithConfig.get(`/contractor/dashboard/contract`)
}

export const signContract = async (payload: any) => {
  return axiosWithConfig.put(`/contractor/dashboard/sign-contract`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const logout = async () => {
  return axiosWithConfig.get(`/auth/contractor/logout`).then(() => {
    window.location.href = '/'
  })
}

export const getPublicProfile = async (id: string) => {
  return axiosWithConfig.get(`/contractor/public/profile/${id}`)
}

export const publicProfileContact = async (id: string, payload: any) => {
  return axiosWithConfig.post(`/contractor/public/profile/${id}/contact`, payload, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  })
}

export const acceptAssemblyStartDate = async (id: string) => {
  return axiosWithConfig.post(`/contractor/dashboard/${id}/accept-start-date`)
}

export const onboadingComplete = async () => {
  return axiosWithConfig.put(`/contractor/dashboard/complete-onboarding`)
}