import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { theme } from '@assembly/theme'
import { ThemeProvider } from '@mui/material/styles'
import { SideNavbar } from '@assembly/components'
import { RoutePaths } from '@assembly/constants'
import { AppProvider, SnackBarProvider } from '@assembly/contexts'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import {
  ContractorProfile,
  ContractorAssemblies,
  ContractorPayoutSetup,
  ContractorPayoutInvoices,
  Login,
  Contract,
  ContractorProfilePublic,
  AssemblyTemplate,
  Onboarding,
  FAQ,
} from '@assembly/pages'
import { checkSessionIsValid, getProfile } from './api/contractor'
import React from 'react'
import { useIntercom } from 'react-use-intercom'
import TimeOff from "@assembly/pages/TimeOff";
import TaxForms from "@assembly/pages/TaxForms";
import PartnerTraining from "@assembly/pages/PartnerTraining";
import PartnerOnboarding from "@assembly/pages/PartnerOnboarding";

function App() {
  const didMountRef = React.useRef(false)
  const [showLogin, setShowLogin] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const isPublicRoute =
    window.location.pathname.includes('public') ||
    window.location.pathname.includes('talent')
  const [showOnboarding, setShowOnboarding] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (isPublicRoute) {
      return
    }

    if (!didMountRef.current) {
      checkSessionIsValidAsync()
      didMountRef.current = true
    }
  }, [])

  const checkSessionIsValidAsync = async () => {
    try {
      setIsLoading(true)
      const response = await checkSessionIsValid()

      if (response.status === 200) {
        const { data } = await getProfile()
        if (!data.hasCompletedOnboarding && data.onboardingPages.length > 0) {
          setShowOnboarding(true)
        }
      }

      setIsLoading(false)
    } catch (error: any) {
      console.error(error)
      setIsLoading(false)

      if (error.response && error.response.status === 403) {
        setShowLogin(true)
      }
    }
  }

  const renderContent = () => {
    if (isPublicRoute) {
      return (
        <BrowserRouter>
          <Routes>
            <Route
              path={RoutePaths.ContractorProfilePublic}
              Component={ContractorProfilePublic}
            />
            <Route
              path={RoutePaths.AssemblyPublic}
              Component={AssemblyTemplate}
            />
          </Routes>
        </BrowserRouter>
      )
    }

    if (showLogin) {
      return <Login />
    }

    if (showOnboarding) {
      return <Onboarding />
    }

    if (isLoading) {
      return <div></div>
    }

    return <PrivateRoutes />
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <AppProvider>
        <ThemeProvider theme={theme}>
          <SnackBarProvider>{renderContent()}</SnackBarProvider>
        </ThemeProvider>
      </AppProvider>
    </LocalizationProvider>
  )
}

const PrivateRoutes = () => {
  const didMountRef = React.useRef(false)
  const { boot } = useIntercom()
  const internAdminBase = process.env.REACT_APP_ENV_INTERNAL_ADMIN_BASE_URL

  React.useEffect(() => {
    if (!didMountRef.current) {
      bootIntercom()
      didMountRef.current = true
    }
  }, [])

  const bootIntercom = async () => {
    try {
      const { data } = await getProfile()
      if (data) {
        // boot intercom
        boot({
          name: data.firstName + ' ' + data.lastName,
          email: data.assemblyEmail,
          phone: data.phone,
          customAttributes: {
            contractor_personal_email: data.email,
            contractor_has_completed_onboarding: data.hasCompletedOnboarding,
            contractor_has_signed_contract: data.hasSignedContract,
            contractor_link: internAdminBase + '/resources/' + data.id,
          },
        })
      }
    } catch (error: any) {
      console.error('Failed to boot intercom')
    }
  }

  return (
    <BrowserRouter>
      <SideNavbar>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={RoutePaths.ContractorProfile} replace />}
          />
          <Route
            path={RoutePaths.ContractorProfile}
            Component={ContractorProfile}
          />
          <Route
            path={RoutePaths.ContractorAssemblies}
            Component={ContractorAssemblies}
          />
          <Route
            path={RoutePaths.ContractorPayoutSetup}
            Component={ContractorPayoutSetup}
          />
          <Route
            path={RoutePaths.ContractorPayoutInvoices}
            Component={ContractorPayoutInvoices}
          />
          <Route path={RoutePaths.Contract} Component={Contract} />
          <Route path={RoutePaths.TimeOff} Component={TimeOff} />
          <Route path={RoutePaths.FAQ} Component={FAQ} />
          <Route path={RoutePaths.PartnerOnboarding} Component={PartnerOnboarding} />
          <Route path={RoutePaths.PartnerTraining} Component={PartnerTraining} />
        </Routes>
      </SideNavbar>
    </BrowserRouter>
  )
}

export default App
