import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAppStore } from '@assembly/hooks'
import {
  Avatar,
  Button,
  CircularProgress,
  Collapse,
  Menu,
  colors,
} from '@mui/material'
import { RoutePaths } from '@assembly/constants'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LinearScaleIcon from '@mui/icons-material/LinearScale'
import PaidIcon from '@mui/icons-material/Paid'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import SettingsIcon from '@mui/icons-material/Settings'
import ReceiptIcon from '@mui/icons-material/Receipt'
import PaymentIcon from '@mui/icons-material/Payment'
import HandshakeIcon from '@mui/icons-material/Handshake'
import { theme } from '@assembly/theme'
import { logout } from '@assembly/api/contractor'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import { useIntercom } from 'react-use-intercom'
import NoteIcon from '@mui/icons-material/Note'
import RequestPageIcon from '@mui/icons-material/RequestPage';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

const drawerWidth = 240

interface Props {
  children: any
  window?: () => Window
}

const navItems: { name: string; path: string; icon: any }[] = [
  {
    name: 'Profile',
    path: RoutePaths.ContractorProfile,
    icon: <AccountCircleIcon />,
  },
  {
    name: 'Assemblies',
    path: RoutePaths.ContractorAssemblies,
    icon: <LinearScaleIcon />,
  },
]

const payoutNavItems: { name: string; path: string; icon: any }[] = [
  // {
  //   name: 'Entries',
  //   path: RoutePaths.ContractorPayoutEntries,
  //   icon: <PaymentIcon />,
  // },
  {
    name: 'Invoices',
    path: RoutePaths.ContractorPayoutInvoices,
    icon: <ReceiptIcon />,
  },
  {
    name: 'Setup',
    path: RoutePaths.ContractorPayoutSetup,
    icon: <SettingsIcon />,
  },
]

const contractNavItem = {
  name: 'Contract',
  path: RoutePaths.Contract,
  icon: <HandshakeIcon />,
}

const faqNavItem = {
  name: 'FAQ',
  path: RoutePaths.FAQ,
  icon: <NoteIcon />,
}

const partnerOnboardingNavItem = {
    name: 'Partner Onboarding',
    path: RoutePaths.PartnerOnboarding,
    icon: <RequestPageIcon />,
}

const partnerTrainingNavItem = {
    name: 'Partner Training',
    path: RoutePaths.PartnerTraining,
    icon: <RequestPageIcon />,
}


const timeOffNavItem = {
  name: 'Time Off Request',
  path: RoutePaths.TimeOff,
  icon: <AccessTimeFilledIcon/>,
}

const supportNavItem = {
  name: 'Support',
  path: '',
  icon: <ContactSupportIcon />,
}

export default function SideNavbar(props: Props) {
  const { window, children } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const appStore = useAppStore()
  const [nestedOpen, setNestedOpen] = React.useState(true)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  )
  const { showNewMessage } = useIntercom()

  const handleClickPayout = () => {
    setNestedOpen(!nestedOpen)
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const getTitle = () => {
    const foundNavItem = [
        ...navItems,
        ...payoutNavItems,
        contractNavItem,
        faqNavItem,
        partnerOnboardingNavItem,
        partnerTrainingNavItem,
        timeOffNavItem
    ].find((navItem) => navItem.path === location.pathname)
    return (
      foundNavItem?.name || (
        <CircularProgress size={25} sx={{ color: 'white' }} />
      )
    )
  }

  const isNavItemSelected = (path: string) => {
    return location.pathname.includes(path)
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogout = async () => {
    try {
      await logout()
    } catch (error) {
      console.error(error)
    }
  }

  const handleClickSupport = () => {
    showNewMessage(
      'Hello there ! I need some help with my Partner Profile. I have included some details request is below. [Please add details]'
    )
  }

  const drawer = (
    <div>
      <Toolbar disableGutters>
        <Box width="100%" display="flex" pl="20px" marginTop={2}>
          <img
            src={require('../../assets/logo.png')}
            alt="logo"
            width="140px"
          />
        </Box>
      </Toolbar>
      <List sx={{ marginTop: '40px' }}>
        {navItems.map((navItem, index) => (
          <ListItem key={index}>
            <ListItemButton
              selected={location.pathname === navItem.path}
              onClick={() => navigate(navItem.path)}
              sx={{
                backgroundColor: isNavItemSelected(navItem.path)
                  ? `${theme.palette.primary.main} !important`
                  : 'unset',
                borderRadius: '5px',
                color: isNavItemSelected(navItem.path) ? '#fff' : '#000',
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: '40px',
                  color: isNavItemSelected(navItem.path) ? '#fff' : '#000',
                }}
              >
                {navItem.icon}
              </ListItemIcon>
              <ListItemText primary={navItem.name} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem>
          <ListItemButton
            onClick={handleClickPayout}
            sx={{
              borderRadius: '5px',
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: '40px',
                color: '#000',
              }}
            >
              <PaidIcon />
            </ListItemIcon>
            <ListItemText primary="Payout" />
            {nestedOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={nestedOpen} timeout="auto" unmountOnExit>
          <List component="div">
            {payoutNavItems.map((navItem, index) => (
              <ListItemButton
                selected={location.pathname === navItem.path}
                key={index}
                onClick={() => navigate(navItem.path)}
                sx={{
                  ml: 6,
                  mr: 2,
                  backgroundColor: isNavItemSelected(navItem.path)
                    ? `${theme.palette.primary.main} !important`
                    : 'unset',
                  borderRadius: '5px',
                  color: isNavItemSelected(navItem.path) ? '#fff' : '#000',
                }}
              >
                <ListItemIcon
                  sx={{
                    color: isNavItemSelected(navItem.path) ? '#fff' : '#000',
                  }}
                >
                  {navItem.icon}
                </ListItemIcon>
                <ListItemText primary={navItem.name} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
        <ListItem>
          <ListItemButton
            selected={location.pathname === contractNavItem.path}
            onClick={() => navigate(contractNavItem.path)}
            sx={{
              backgroundColor: isNavItemSelected(contractNavItem.path)
                ? `${theme.palette.primary.main} !important`
                : 'unset',
              borderRadius: '5px',
              color: isNavItemSelected(contractNavItem.path) ? '#fff' : '#000',
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: '40px',
                color: isNavItemSelected(contractNavItem.path)
                  ? '#fff'
                  : '#000',
              }}
            >
              {contractNavItem.icon}
            </ListItemIcon>
            <ListItemText primary={contractNavItem.name} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            selected={location.pathname === faqNavItem.path}
            onClick={() => navigate(faqNavItem.path)}
            sx={{
              backgroundColor: isNavItemSelected(faqNavItem.path)
                ? `${theme.palette.primary.main} !important`
                : 'unset',
              borderRadius: '5px',
              color: isNavItemSelected(faqNavItem.path) ? '#fff' : '#000',
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: '40px',
                color: isNavItemSelected(faqNavItem.path)
                  ? '#fff'
                  : '#000',
              }}
            >
              {faqNavItem.icon}
            </ListItemIcon>
            <ListItemText primary={faqNavItem.name} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            selected={location.pathname === partnerOnboardingNavItem.path}
            onClick={() => navigate(partnerOnboardingNavItem.path)}
            sx={{
              backgroundColor: isNavItemSelected(partnerOnboardingNavItem.path)
                ? `${theme.palette.primary.main} !important`
                : 'unset',
              borderRadius: '5px',
              color: isNavItemSelected(partnerOnboardingNavItem.path) ? '#fff' : '#000',
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: '40px',
                color: isNavItemSelected(partnerOnboardingNavItem.path)
                  ? '#fff'
                  : '#000',
              }}
            >
              {partnerOnboardingNavItem.icon}
            </ListItemIcon>
            <ListItemText primary={partnerOnboardingNavItem.name} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            selected={location.pathname === partnerTrainingNavItem.path}
            onClick={() => navigate(partnerTrainingNavItem.path)}
            sx={{
              backgroundColor: isNavItemSelected(partnerTrainingNavItem.path)
                ? `${theme.palette.primary.main} !important`
                : 'unset',
              borderRadius: '5px',
              color: isNavItemSelected(partnerTrainingNavItem.path) ? '#fff' : '#000',
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: '40px',
                color: isNavItemSelected(partnerTrainingNavItem.path)
                  ? '#fff'
                  : '#000',
              }}
            >
              {partnerTrainingNavItem.icon}
            </ListItemIcon>
            <ListItemText primary={partnerTrainingNavItem.name} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
              selected={location.pathname === timeOffNavItem.path}
              onClick={() => navigate(timeOffNavItem.path)}
              sx={{
                backgroundColor: isNavItemSelected(timeOffNavItem.path)
                    ? `${theme.palette.primary.main} !important`
                    : 'unset',
                borderRadius: '5px',
                color: isNavItemSelected(timeOffNavItem.path) ? '#fff' : '#000',
              }}
          >
            <ListItemIcon
                sx={{
                  minWidth: '40px',
                  color: isNavItemSelected(timeOffNavItem.path)
                      ? '#fff'
                      : '#000',
                }}
            >
              {timeOffNavItem.icon}
            </ListItemIcon>
            <ListItemText primary={timeOffNavItem.name} />
          </ListItemButton>
        </ListItem>


        <ListItem>
          <ListItemButton
            onClick={handleClickSupport}
            sx={{
              backgroundColor: 'unset',
              borderRadius: '5px',
              color: '#000',
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: '40px',
                color: '#000',
              }}
            >
              {supportNavItem.icon}
            </ListItemIcon>
            <ListItemText primary={supportNavItem.name} />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: '#fff',
          color: '#000',
          borderBottom: '1px solid #eceefe',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box width="100%" display="flex" justifyContent="space-between">
            <Typography variant="h5" noWrap component="div" fontWeight={600}>
              {appStore.store.appBarTitle || getTitle()}
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                sx={{
                  bgcolor: '#1b17f514',
                  color: '#000',
                  fontWeight: 600,
                }}
              >
                <PermIdentityIcon />
              </Avatar>
            </IconButton>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Box padding="10px 15px">
                <Box display="flex" justifyContent="center" marginTop={2}>
                  <Button variant="outlined" onClick={handleLogout}>
                    Logout
                  </Button>
                </Box>
              </Box>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          PaperProps={{
            sx: {
              backgroundColor: colors.grey[50],
              border: 'none',
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
          PaperProps={{
            sx: {
              backgroundColor: colors.grey[50],
              border: 'none',
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  )
}
