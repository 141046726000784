import { Contractor, Highlight, Portfolio } from '@assembly/types'
import {
  getMediaUrl,
  interpolateRouteUtil,
  stringAvatar,
} from '@assembly/utils'
import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  IconButton,
  Link,
  Tooltip,
  Typography,
  colors,
} from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import logoWhite from '@assembly/assets/logo-white.png'
import WorkWithMeDialog from './WorkWithMeDialog'
import LinkIcon from '@mui/icons-material/Link'
import { RoutePaths } from '@assembly/constants'
import VideoAndPortfolioTabs from './VideoAndPortfolioTabs'

const StyledBox = styled(Box)(() => ({
  border: '1px solid',
  borderColor: colors.grey[200],
  borderRadius: '10px',
  marginTop: '10px',
  marginBottom: '10px',
}))

const MainInfoBox = styled(Box)(() => ({
  background: '#FFFFFF',
  borderRadius: '10px',
  position: 'relative',
  paddingBottom: '30px',
}))

const BannerGradient = styled(Box)(({ theme }) => ({
  height: '200px',
  background: '#505acd4d',
  borderTopRightRadius: '10px',
  borderTopLeftRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    height: '150px',
  },
}))

const ProfilePicture = styled(Avatar)(({ theme }) => ({
  width: '150px',
  height: '150px',
  position: 'absolute',
  left: '30px',
  top: '100px',
  border: '8px solid white',
  [theme.breakpoints.down('sm')]: {
    width: '100px',
    height: '100px',
    left: '15px',
  },
}))

const MainInfoInnerBox = styled(Box)(({ theme }) => ({
  marginTop: '15px',
  marginLeft: '30px',
  marginRight: '30px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '15px',
  },
}))

const Name = styled(Typography)(({ theme }) => ({
  fontSize: '30px',
  fontWeight: 500,
  fontFamily: 'Space Grotesk',
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
  },
}))

const CapabilitiesBox = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  marginTop: '10px',
}))

const CapabilityChip = styled(Chip)(() => ({
  fontWeight: 600,
  color: '#fff',
}))

const SectionBox = styled(Box)(({ theme }) => ({
  background: '#FFFFFF',
  borderRadius: '10px',
  padding: '20px 30px 20px 30px',
  [theme.breakpoints.down('sm')]: {
    padding: '20px 15px 20px 15px',
  },
}))

const SectionTitle = styled(Typography)(() => ({
  fontWeight: 500,
  fontFamily: 'Space Grotesk',
}))

const InfoItemsBox = styled(Box)(() => ({
  width: '100%',
  marginTop: '20px',
}))

const InfoItemBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '15px 0 10px 0',
}))

const InfoLabel = styled(Typography)(() => ({
  fontFamily: 'Space Grotesk',
}))

const InfoValue = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: '16px',
}))

const BioBox = styled(Box)(() => ({
  marginTop: '10px',
  fontFamily: 'Roboto',
  lineHeight: '23px',
}))

const MenuButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '20px',
  marginRight: '15px',
}))

const StyledLink = styled(Link)(() => ({
  fontSize: '18px',
  fontFamily: 'Space Grotesk',
}))

const ProfileLinkBox = styled(Box)(() => ({
  marginTop: '10px',
}))

const WorkWithMeButton = styled(Button)(() => ({
  boxShadow: 'none',
  textTransform: 'none',
}))

const WorkWithMeButtonBottom = styled(Button)(() => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '20px',
  padding: '10px 30px 10px 30px',
}))

const WorkWithMeButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '50px',
  marginTop: '50px',
}))

const BannerLogo = styled('img')(({ theme }) => ({
  width: '200px',
  [theme.breakpoints.down('sm')]: {
    width: '120px',
  },
}))

const StyledIconButton = styled(IconButton)(() => ({
  marginRight: '20px',
}))

type PublicProfileProps = {
  data: Contractor
}

export default function PublicProfile({ data }: PublicProfileProps) {
  const tooltipLeaveDelay = 200
  const [isWorkWithMeDialogOpen, setIsWorkWithMeDialogOpen] =
    React.useState<boolean>(false)
  const [copyTooltipText, setCopyTooltipText] =
    React.useState('Copy Profile Link')

  const handleClickLinkCopy = () => {
    const origin = window.location.origin
    const path = interpolateRouteUtil(RoutePaths.ContractorProfilePublic, {
      id: data.id,
    })
    const link = origin + path
    navigator.clipboard.writeText(link)
    setCopyTooltipText('Copied')
  }

  const handleMouseLeaveCopy = () => {
    setTimeout(() => {
      setCopyTooltipText('Copy Profile Link')
    }, tooltipLeaveDelay)
  }

  if (!data) {
    return <></>
  }

  return (
    <Container maxWidth="md">
      <StyledBox>
        <MainInfoBox>
          <BannerGradient>
            <Button href="https://assembly-industries.com">
              <BannerLogo src={logoWhite} alt="logo" />
            </Button>
          </BannerGradient>
          <ProfilePicture
            sx={{ bgcolor: 'primary.main' }}
            src={getMediaUrl(data?.profilePicture?.media || '')}
          />
          <MenuButtonBox>
            <Tooltip title={copyTooltipText}>
              <StyledIconButton
                onClick={handleClickLinkCopy}
                onMouseLeave={handleMouseLeaveCopy}
              >
                <LinkIcon />
              </StyledIconButton>
            </Tooltip>

            <WorkWithMeButton
              variant="contained"
              onClick={() => setIsWorkWithMeDialogOpen(true)}
            >
              Work With Me
            </WorkWithMeButton>
          </MenuButtonBox>
          <MainInfoInnerBox>
            <Name>
              {data?.firstName} {data?.lastName}
            </Name>
            <Typography>{data?.label}</Typography>
          </MainInfoInnerBox>
        </MainInfoBox>
        {data && data.introVideo && (
          <SectionBox>
            <SectionTitle variant="h5">Video Intro</SectionTitle>
            <IntroVideo url={getMediaUrl(data.introVideo.media || '')} />
          </SectionBox>
        )}
        <SectionBox>
          <SectionTitle variant="h5">Bio</SectionTitle>
          <BioBox>
            {data.bio ? <ReactMarkdown children={data?.bio || ''} /> : '---'}
          </BioBox>
        </SectionBox>
        <SectionBox>
          <SectionTitle variant="h5">Profile Link</SectionTitle>
          {data?.profileLink ? (
            <ProfileLinkBox>
              <StyledLink href={data.profileLink} target="_blank">
                {data.profileLink}
              </StyledLink>
            </ProfileLinkBox>
          ) : (
            '---'
          )}
        </SectionBox>
        {data.capabilities.length > 0 && (
          <SectionBox>
            <SectionTitle variant="h5">Capabilities</SectionTitle>
            <CapabilitiesBox>
              {data?.capabilities?.map((capability: any, index: number) => (
                <CapabilityChip
                  key={index}
                  label={capability.name}
                  sx={{
                    ...stringAvatar(capability.name).sx,
                  }}
                />
              ))}
            </CapabilitiesBox>
          </SectionBox>
        )}
        {data.skills && data.skills.length > 0 && (
          <SectionBox>
            <SectionTitle variant="h5">Skills</SectionTitle>
            <CapabilitiesBox>
              {data?.skills?.map((item: any, index: number) => (
                <CapabilityChip
                  key={index}
                  label={item.name}
                  sx={{
                    ...stringAvatar(item.name).sx,
                  }}
                />
              ))}
            </CapabilitiesBox>
          </SectionBox>
        )}
        {(data.highlights && data.highlights.length > 0 ||
          data.portfolio && data.portfolio.length > 0)
            && (
            <SectionBox>
              <VideoAndPortfolioTabs
                videoInterviewLink={data.videoInterviewLink}
                highlights={data.highlights as Highlight[]}
                portfolio={data.portfolio as Portfolio[]}
              />
            </SectionBox>
          )}
        <SectionBox>
          <SectionTitle variant="h5">Availability</SectionTitle>
          <InfoItemsBox>
            <InfoItemBox>
              <InfoLabel>Start Date</InfoLabel>
              <InfoValue>
                {data.availableStartDate && data.availableStartDate === "Invalid date" ? "Available Now" :
                    data.availableStartDate ? moment(data?.availableStartDate, 'YYYY-MM-DD').format(
                        'MMM DD, YYYY'
                    ) : 'Available Now'}
              </InfoValue>
            </InfoItemBox>
            {/*<Divider />*/}
            {/*<InfoItemBox>*/}
            {/*  <InfoLabel>Hourly Rate</InfoLabel>*/}
            {/*  <InfoValue>*/}
            {/*    {data?.desiredHourlyRate*/}
            {/*      ? '$ ' + data.desiredHourlyRate*/}
            {/*      : '---'}*/}
            {/*  </InfoValue>*/}
            {/*</InfoItemBox>*/}
            {/*<Divider />*/}
            {/*<InfoItemBox>*/}
            {/*  <InfoLabel>Desired Weekly Hours</InfoLabel>*/}
            {/*  <InfoValue>*/}
            {/*    {data?.desiredWeeklyHours*/}
            {/*      ? data.desiredWeeklyHours + ' hrs'*/}
            {/*      : '---'}*/}
            {/*  </InfoValue>*/}
            {/*</InfoItemBox>*/}
            {/*<Divider />*/}
            {/*<InfoItemBox>*/}
            {/*  <InfoLabel>Available Weekly Hours</InfoLabel>*/}
            {/*  <InfoValue>*/}
            {/*    {data?.availableWeeklyHours*/}
            {/*      ? data.availableWeeklyHours + ' hrs'*/}
            {/*      : '---'}*/}
            {/*  </InfoValue>*/}
            {/*</InfoItemBox>*/}
          </InfoItemsBox>
        </SectionBox>

        <WorkWithMeButtonBox>
          <WorkWithMeButtonBottom
            variant="contained"
            onClick={() => setIsWorkWithMeDialogOpen(true)}
          >
            Work With Me
          </WorkWithMeButtonBottom>
        </WorkWithMeButtonBox>
      </StyledBox>
      <WorkWithMeDialog
        open={isWorkWithMeDialogOpen}
        onClose={() => setIsWorkWithMeDialogOpen(false)}
        id={data.id}
      />
    </Container>
  )
}

function IntroVideo({ url }: any) {
  const videoRef = React.useRef<HTMLVideoElement>(null)

  React.useEffect(() => {
    videoRef.current?.load()
  }, [url])

  return (
    <video
      controls
      ref={videoRef}
      width={'100%'}
      style={{ marginTop: '15px', borderRadius: '5px' }}
    >
      <source src={url} />
    </video>
  )
}
