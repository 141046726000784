import React from 'react'

export default function TimeOff() {
  return (
    <React.Fragment>
      <DocumentViewer />
    </React.Fragment>
  )
}

const DocumentViewer = () => {
  return (
    <div style={{ height: '85vh' }}>
      <iframe
        style={{ border: 'none' }}
        width="100%"
        height="100%"
        src="https://form.asana.com/?k=9e0rhUrWDnHY8D75P5_MUQ&d=1204433356905897&embed=true"
      ></iframe>
    </div>
  )
}
