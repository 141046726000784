import { getProfile } from '@assembly/api/contractor'
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Divider,
  Typography,
  colors,
} from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'
import { getApiErrorMsg, getMediaUrl, stringAvatar } from '@assembly/utils'
import moment from 'moment'
import CustomizedMenus from '@assembly/pages/Contractor/Profile/Menu'
import EditProfileDialog from '@assembly/pages/Contractor/Profile/EditProfileDialog'
import ReactMarkdown from 'react-markdown'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import {Contractor, Media} from '@assembly/types'
import { SnackbarType } from '@assembly/contexts'
import {baseURL} from "@assembly/config/axios";

const StyledBox = styled(Box)(() => ({
  border: '1px solid',
  borderColor: colors.grey[200],
  borderRadius: '10px',
}))

const MainInfoBox = styled(Box)(() => ({
  background: '#FFFFFF',
  borderRadius: '10px',
  position: 'relative',
  paddingBottom: '30px',
}))

const BannerGradient = styled(Box)(() => ({
  height: '200px',
  background: '#505acd4d',
  borderTopRightRadius: '10px',
  borderTopLeftRadius: '10px',
}))

const ProfilePicture = styled(Avatar)(() => ({
  width: '150px',
  height: '150px',
  position: 'absolute',
  left: '30px',
  top: '100px',
  border: '8px solid white',
}))

const MainInfoInnerBox = styled(Box)(() => ({
  marginTop: '10px',
  marginLeft: '30px',
  marginRight: '30px',
}))

const Name = styled(Typography)(() => ({
  fontSize: '30px',
  fontWeight: 500,
  fontFamily: 'Space Grotesk',
}))

const CapabilitiesBox = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  marginTop: '20px',
}))

const CapabilityChip = styled(Chip)(() => ({
  fontWeight: 600,
  color: '#fff',
}))

const SectionBox = styled(Box)(() => ({
  background: '#FFFFFF',
  borderRadius: '10px',
  marginTop: '20px',
  padding: '20px 30px 20px 30px',
}))

const SectionTitle = styled(Typography)(() => ({
  fontWeight: 500,
  fontFamily: 'Space Grotesk',
}))

const InfoItemsBox = styled(Box)(() => ({
  width: '100%',
  marginTop: '20px',
}))

const InfoItemBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '15px 0 10px 0',
}))

const InfoLabel = styled(Typography)(() => ({
  fontFamily: 'Space Grotesk',
}))

const InfoValue = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: '18px',
}))

const BioBox = styled(Box)(() => ({
  marginTop: '10px',
}))

const MenuButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '20px',
  marginRight: '15px',
}))

const ContractorProfile = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [editDialogOpen, setEditDialogOpen] = React.useState<boolean>(false)
  const didMountRef = React.useRef(false)
  const { store, setContractorProfile } = useAppStore()
  const data = store.contractorProfile
  const { addAlert } = useSnackbars()

  React.useEffect(() => {
    if (!didMountRef.current) {
      if (!store.isContractorProfileLoaded) {
        getProfileAsync()
      } else {
        setIsLoading(false)
      }
      didMountRef.current = true
    }
  }, [])

  const getProfileAsync = async () => {
    try {
      setIsLoading(true)
      const { data } = await getProfile()
      setContractorProfile(data)
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const handleClickEdit = () => {
    setEditDialogOpen(true)
  }

  const handleCloseEditProfileDialog = () => {
    setEditDialogOpen(false)
  }

  const handleEditProfileSuccess = (profile: Contractor) => {
    setContractorProfile(profile)
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <StyledBox>
      <MainInfoBox>
        <BannerGradient />
        <ProfilePicture
          sx={{ bgcolor: 'primary.main' }}
          src={getMediaUrl(data?.profilePicture?.media || '')}
        />
        <MenuButtonBox>
          <CustomizedMenus onClickEdit={handleClickEdit} />
        </MenuButtonBox>
        <MainInfoInnerBox>
          <Name>
            {data?.firstName} {data?.lastName}
          </Name>
          <Typography>{data?.label}</Typography>
          <CapabilitiesBox>
            {data?.capabilities?.map((capability: any, index: number) => (
              <CapabilityChip
                key={index}
                label={capability.name}
                sx={{
                  ...stringAvatar(capability.name).sx,
                }}
              />
            ))}
          </CapabilitiesBox>
        </MainInfoInnerBox>
      </MainInfoBox>
      <SectionBox>
        <SectionTitle variant="h5">Bio</SectionTitle>
        <BioBox>
          {data.bio ? <ReactMarkdown children={data?.bio || ''} /> : '---'}
        </BioBox>
      </SectionBox>
      <SectionBox>
        <SectionTitle variant="h5">Availability</SectionTitle>
        <InfoItemsBox>
          <InfoItemBox>
            <InfoLabel>Start Date</InfoLabel>
            <InfoValue>
              {data.availableStartDate && data.availableStartDate === "Invalid date" ? "Available Now" :
                  data.availableStartDate ? moment(data?.availableStartDate, 'YYYY-MM-DD').format(
                    'MMM DD, YYYY'
                  ) : 'Available Now'}
            </InfoValue>
          </InfoItemBox>
          {/*<Divider />*/}
          {/*<InfoItemBox>*/}
          {/*  <InfoLabel>Hourly Rate</InfoLabel>*/}
          {/*  <InfoValue>*/}
          {/*    {data?.desiredHourlyRate ? '$' + data.desiredHourlyRate : '---'}*/}
          {/*  </InfoValue>*/}
          {/*</InfoItemBox>*/}
          {/*<Divider />*/}
          {/*<InfoItemBox>*/}
          {/*  <InfoLabel>Desired Weekly Hours</InfoLabel>*/}
          {/*  <InfoValue>*/}
          {/*    {data?.desiredWeeklyHours*/}
          {/*      ? data.desiredWeeklyHours + 'hrs'*/}
          {/*      : '---'}*/}
          {/*  </InfoValue>*/}
          {/*</InfoItemBox>*/}
          {/*<Divider />*/}
          {/*<InfoItemBox>*/}
          {/*  <InfoLabel>Available Weekly Hours</InfoLabel>*/}
          {/*  <InfoValue>*/}
          {/*    {data?.availableWeeklyHours*/}
          {/*      ? data.availableWeeklyHours + 'hrs'*/}
          {/*      : '---'}*/}
          {/*  </InfoValue>*/}
          {/*</InfoItemBox>*/}
        </InfoItemsBox>
      </SectionBox>
      <SectionBox>
        <SectionTitle variant="h5">Contact</SectionTitle>
        <InfoItemsBox>
          <InfoItemBox>
            <InfoLabel>Email</InfoLabel>
            <InfoValue>{data?.email}</InfoValue>
          </InfoItemBox>
          <Divider />
          <InfoItemBox>
            <InfoLabel>Assembly Email</InfoLabel>
            <InfoValue>{data?.assemblyEmail || '---'}</InfoValue>
          </InfoItemBox>
          <Divider />
          <InfoItemBox>
            <InfoLabel>Phone</InfoLabel>
            <InfoValue>{data.phone ? '+' + data.phone : '---'}</InfoValue>
          </InfoItemBox>
        </InfoItemsBox>
      </SectionBox>
      <SectionBox>
        <SectionTitle variant="h5">Tax Forms</SectionTitle>
        <InfoItemsBox>
            <InfoItemBox>
                <InfoLabel>W8BEN form</InfoLabel>
                <InfoValue>
                  {data.w8BEN ? (
                      <a href={baseURL + "/media/private/resource/file/" + (data.w8BEN as Media).id} target="_blank"> Download form </a>
                  ) : 'No W8BEN form uploaded'}
                </InfoValue>
            </InfoItemBox>
          </InfoItemsBox>
        </SectionBox>
      <EditProfileDialog
        open={editDialogOpen}
        onClose={handleCloseEditProfileDialog}
        onSuccess={handleEditProfileSuccess}
        profile={data}
      />
    </StyledBox>
  )
}

export default ContractorProfile
