import { theme } from '@assembly/theme'
import {
  Highlight,
  HighlightType,
  Portfolio,
  PortfolioType,
} from '@assembly/types'
import {
  Box,
  Button,
  colors,
  Tab,
  Tabs,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { getMediaUrl } from '@assembly/utils'
import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

type VideoAndPortfolioTabsProps = {
  videoInterviewLink: string
  highlights: Highlight[]
  portfolio: Portfolio[]
}

const MainBox = styled(Box)(() => ({
  marginTop: '10px',
}))

const TabsBox = styled(Box)(() => ({
  borderBottom: 1,
  borderColor: 'divider',
}))

const TabItem = styled(Tab)(({ theme }) => ({
  fontFamily: 'Space Grotesk',
  fontSize: '16px',
  textTransform: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    padding: '10px',
  },
}))

const useStyles = makeStyles(() => ({
  arrow: {
    '&:before': {
      color: '#000',
    },
  },
}))

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => {
  const classes = useStyles()
  return (
    <Tooltip {...props} classes={{ popper: className, arrow: classes.arrow }} />
  )
})(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
}))

const AnswerText = styled(Typography)(() => ({
  fontFamily: 'Roboto',
  marginTop: '20px',
}))

const QuestionLabel = styled(Typography)(() => ({
  fontSize: '18px',
  display: 'inline',
}))

const AnswerLabel = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 500,
}))

const VerticalTabItem = styled(TabItem)(() => ({
  width: '50px',
  minWidth: '50px',
  fontWeight: 600,
  backgroundColor: colors.grey[100],
  color: '#868686',
  '&.Mui-selected': {
    color: '#ffffff !important',
    backgroundColor: theme.palette.primary.main,
  },
}))

const VeticalTabs = styled(Tabs)(() => ({
  width: '50px',
  borderRadius: '5px',
  maxHeight: '600px',
  minWidth: '50px',
}))

const ContentBox = styled(Box)(() => ({
  marginTop: '30px',
  display: 'flex',
  gap: '15px',
  minHeight: '500px',
  border: `1px solid ${colors.grey[200]}`,
  padding: '15px',
  borderRadius: '5px',
}))

const PortfolioTitle = styled(Typography)(() => ({
  fontSize: '22px',
  fontWeight: 500,
}))

const PortfolioDescription = styled(Typography)(() => ({
  fontSize: '16px',
  marginTop: '5px',
  color: '#868686',
}))

const Description = styled(Typography)(() => ({
  fontSize: '15px',
  marginTop: '5px',
  color: '#333333',
}))

const QuestionBox = styled(Box)(() => ({
  background: '#1b17f514',
  padding: '15px',
  borderRadius: '5px',
}))

const AnswerBox = styled(Box)(() => ({
  padding: '15px',
  background: colors.grey[50],
  borderRadius: '5px',
  marginTop: '10px',
}))

// class for iframe without borders and scrollbars, no padding and margin, full width and height
const VideoInterviewIFrame = styled('iframe')(() => ({
  border: 'none',
  margin: 0,
  padding: 0,
  width: '800px',
  height: '850px',
  // no scrollbars
  overflow: 'hidden',
  // no outline on focus
  outline: 'none',
  // no resize
  resize: 'none',
  // no scroll on mobile devices
  '-webkit-overflow-scrolling': 'none',
  '-ms-overflow-style': 'none',
  // no scrollbars
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}))

export default function VideoAndPortfolioTabs({
  videoInterviewLink,
  highlights,
  portfolio,
}: VideoAndPortfolioTabsProps) {
  const [value, setValue] = React.useState(0)

  const [videoInterviewTabValue, setVideoInterviewTabValue] = React.useState(0)
  const [highlightsTabValue, setHighlightsTabValue] = React.useState(0)
  const [portfolioTabsValue, setPortfolioTabsValue] = React.useState(0)

  const handleChange = (_: any, newValue: any) => {
    setValue(newValue)
  }

  const handleChangeVideoInterviewTab = (_: any, newValue: any) => {
    setVideoInterviewTabValue(newValue)
  }

  const handleChangeHighlightsTabs = (_: any, newValue: any) => {
    setHighlightsTabValue(newValue)
  }

  const handleChangePortfolioTabs = (_: any, newValue: any) => {
    setPortfolioTabsValue(newValue)
  }

  const tabItems = ['Highlights', 'Portfolio', 'Video Interview']

  const renderVideoInterviewContent = (videoInterviewLink: string) => {
    return (
      <Box>
        <VideoInterviewIFrame src={videoInterviewLink} scrolling="no" />
      </Box>
    )
  }

  const renderHighlightContent = (highlight: Highlight) => {
    switch (highlight.type) {
      case HighlightType.Media:
        return (
          <Box>
            <QuestionBox>
              <Typography
                sx={{
                  fontSize: '24px',
                  marginBottom: '2px',
                  fontWeight: 500,
                }}
              >
                {highlights[highlightsTabValue].title}
              </Typography>
              <Typography sx={{ fontSize: '16px', marginBottom: '10px' }}>
                {highlights[highlightsTabValue].description}
              </Typography>
              <QuestionLabel>
                <span style={{ fontWeight: 500 }}>Question:</span>{' '}
                {highlights[highlightsTabValue].question}
              </QuestionLabel>
            </QuestionBox>
            <Clip url={getMediaUrl(highlight.answerMedia?.media || '')} />
          </Box>
        )
      case HighlightType.Text:
        return (
          <Box>
            <QuestionBox>
              <Typography
                sx={{
                  fontSize: '24px',
                  marginBottom: '2px',
                  fontWeight: 500,
                }}
              >
                {highlights[highlightsTabValue].title}
              </Typography>
              <Typography sx={{ fontSize: '16px', marginBottom: '10px' }}>
                {highlights[highlightsTabValue].description}
              </Typography>
              <QuestionLabel>
                <span style={{ fontWeight: 500 }}>Question:</span>{' '}
                {highlights[highlightsTabValue].question.replace(/\\n/g, '<br/>')}
              </QuestionLabel>
            </QuestionBox>
            <AnswerBox>
              <AnswerLabel>Answer:</AnswerLabel>
              <AnswerText
                dangerouslySetInnerHTML={{
                  __html: (highlight.answerText && highlight.answerText.replace(/\\n/g, '<br/>')) as string,
                }}
              ></AnswerText>
            </AnswerBox>
          </Box>
        )
      case HighlightType.Url:
        return (
          <Box>
            <QuestionBox>
              <Typography
                sx={{
                  fontSize: '24px',
                  marginBottom: '2px',
                  fontWeight: 500,
                }}
              >
                {highlights[highlightsTabValue].title}
              </Typography>
              <Typography sx={{ fontSize: '16px', marginBottom: '10px' }}>
                {highlights[highlightsTabValue].description}
              </Typography>
              <QuestionLabel>
                <span style={{ fontWeight: 500 }}>Question:</span>{' '}
                {highlights[highlightsTabValue].question}
              </QuestionLabel>
            </QuestionBox>
            <AnswerBox>
              <AnswerLabel>Answer:</AnswerLabel>
              <Button
                href={highlight.answerUrl as string}
                target="_blank"
                sx={{
                  textTransform: 'none',
                  padding: 0,
                  marginTop: '5px',
                  fontSize: '15px',
                }}
              >
                {highlight.answerUrl as string}
              </Button>
            </AnswerBox>
          </Box>
        )
    }
  }

  const renderPortfolioContent = (portfolio: Portfolio) => {
    switch (portfolio.type) {
      case PortfolioType.Media:
        const url = getMediaUrl(portfolio.media?.media || '')
        const isPdf = url?.includes('.pdf')

        return <Box>{isPdf && <PDFViewer pdfURL={url} />}</Box>
      case PortfolioType.Url:
        return (
          <Box>
            <Button
              href={portfolio.url as string}
              target="_blank"
              sx={{
                textTransform: 'none',
                padding: 0,
                marginTop: '5px',
                fontSize: '15px',
              }}
            >
              {portfolio.url as string}
            </Button>
          </Box>
        )
    }
  }

  const HighlightsTabContent = () => {
    return (
      <ContentBox>
        <VeticalTabs
          orientation="vertical"
          value={highlightsTabValue}
          onChange={handleChangeHighlightsTabs}
          variant="scrollable"
        >
          {highlights.map((item, index) => (
            <HtmlTooltip
              title={
                <Typography component="span" variant="body2">
                  {item.title}
                </Typography>
              }
              placement="right-start"
              arrow
            >
              <VerticalTabItem key={index} label={index + 1} />
            </HtmlTooltip>
          ))}
        </VeticalTabs>
        <Box>{renderHighlightContent(highlights[highlightsTabValue])}</Box>
      </ContentBox>
    )
  }

  const VideoInterviewTabContent = () => {
    return (
      <ContentBox>
        <Box>{renderVideoInterviewContent(videoInterviewLink)}</Box>
      </ContentBox>
    )
  }

  const PortfolioTabContent = () => {
    return (
      <ContentBox>
        <VeticalTabs
          orientation="vertical"
          value={portfolioTabsValue}
          onChange={handleChangePortfolioTabs}
          variant="scrollable"
        >
          {portfolio.map((item, index) => (
            <HtmlTooltip
              title={
                <Typography component="span" variant="body2">
                  {item.title}
                </Typography>
              }
              placement="right-start"
              arrow
            >
              <VerticalTabItem key={index} label={index + 1} />
            </HtmlTooltip>
          ))}
        </VeticalTabs>
        <Box>
          <PortfolioTitle>{portfolio[portfolioTabsValue].title}</PortfolioTitle>
          <PortfolioDescription>
            {portfolio[portfolioTabsValue].description}
          </PortfolioDescription>
          {renderPortfolioContent(portfolio[portfolioTabsValue])}
        </Box>
      </ContentBox>
    )
  }

  const renderTabsContent = () => {
    switch (value) {
      case 0:
        return <HighlightsTabContent />
      case 1:
        return <PortfolioTabContent />
      case 2:
        return videoInterviewLink && videoInterviewLink.length > 0 ? (
          <VideoInterviewTabContent />
        ) : null
    }
  }

  return (
    <MainBox>
      <TabsBox>
        <Tabs
          orientation={'horizontal'}
          value={value}
          onChange={handleChange}
          variant="scrollable"
        >
          {tabItems.map((item, index) => {
            switch (item) {
              case 'Highlights':
                if (highlights.length === 0) {
                  return null
                }
                break
              case 'Portfolio':
                if (portfolio.length === 0) {
                  return null
                }
                break
              case 'Video Interview':
                if (
                  !videoInterviewLink ||
                  videoInterviewLink.trim().length === 0
                ) {
                  return null
                }
                break
            }

            return (
              <TabItem
                key={index}
                label={item}
                sx={{
                  fontWeight: 600,
                  color: value === index ? 'primary' : '#868686',
                }}
              />
            )
          })}
        </Tabs>
      </TabsBox>
      {renderTabsContent()}
    </MainBox>
  )
}

function Clip({ url }: any) {
  const videoRef = React.useRef<HTMLVideoElement>(null)

  React.useEffect(() => {
    videoRef.current?.load()
  }, [url])

  return (
    <video
      controls
      ref={videoRef}
      width={'100%'}
      style={{ marginTop: '15px', borderRadius: '5px' }}
    >
      <source src={url} />
    </video>
  )
}

function PDFViewer(props: any) {
  const [numPages, setNumPages] = useState<number>()

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages)
  }

  return (
    <Box
      sx={{
        width: '690px',
        height: '700px',
        overflowY: 'scroll',
        overflowX: 'hidden',
        marginTop: '15px',
      }}
    >
      <Document file={props.pdfURL} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            renderAnnotationLayer={false}
            renderTextLayer={false}
            width={690}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        ))}
      </Document>
    </Box>
  )
}
