import * as React from 'react'
import Button from '@mui/lab/LoadingButton'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material'
import { useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import SignatureCanvas from 'react-signature-canvas'
import ReactSignatureCanvas from 'react-signature-canvas'
import { useTheme } from '@mui/material/styles'
import { signContract } from '@assembly/api/contractor'

const transitionDuration: number = 200

interface SignContractDialogTitleProps {
  children?: React.ReactNode
  onClose: () => void
}

function SignContractDialogTitle(props: SignContractDialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface SignContractDialogProps {
  open: boolean
  onClose: () => void
  onSuccess: (data: any) => void
}

export default function SignContractDialog(props: SignContractDialogProps) {
  const { open, onClose, onSuccess } = props

  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const { addAlert } = useSnackbars()
  let canvasRef: ReactSignatureCanvas | null = null
  const theme = useTheme()

  const handleClose = () => {
    setTimeout(() => {}, transitionDuration)
    setIsSaving(false)
    onClose()
  }

  const handleClickClear = () => {
    canvasRef?.clear()
  }

  const handleClickDone = async () => {
    if (canvasRef?.isEmpty()) {
      return
    }

    if (!canvasRef) {
      return
    }

    const dataUrl = canvasRef.getTrimmedCanvas().toDataURL('image/png')
    const res: Response = await fetch(dataUrl)
    const blob: Blob = await res.blob()
    const file = new File([blob], 'signature.png', { type: 'image/png' })

    const payload = new FormData()
    payload.append('thumbnail', 'false')
    payload.append('file', file)

    try {
      setIsSaving(true)
      const { data } = await signContract(payload)
      setIsSaving(false)
      onSuccess(data)
      handleClose()
      addAlert({
        message: 'Contract signed successfully.',
        type: SnackbarType.Success,
      })
    } catch (error: any) {
      setIsSaving(false)
      if (
        error.response &&
        error.response.data &&
        error.response.data.message &&
        typeof error.response.data.message === 'string'
      ) {
        addAlert({
          message: error.response.data.message,
          type: SnackbarType.Error,
        })
      }
    }
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="sm"
        fullWidth
        transitionDuration={transitionDuration}
      >
        <SignContractDialogTitle onClose={handleClose}>
          Signature
        </SignContractDialogTitle>
        <DialogContent dividers={true}>
          <SignatureCanvas
            ref={(ref) => {
              canvasRef = ref
            }}
            penColor={theme.palette.secondary.main}
            canvasProps={{
              width: 550,
              height: 200,
              style: { border: `1px solid #000`, borderRadius: '10px' },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClear}>Clear</Button>
          <Button loading={isSaving} onClick={handleClickDone}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
