import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import { capitalize } from 'lodash'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import { getApiErrorMsg } from '@assembly/utils'
import { Media, Portfolio, PortfolioType } from '@assembly/types'
import { uploadMedia } from '@assembly/api/media'
import { CircularProgressWithLabel } from '@assembly/components'
import isURL from 'validator/lib/isURL'

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: 0,
  marginBottom: theme.spacing(2.5),
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: 0,
  marginBottom: theme.spacing(2.5),
}))

const MediaButtonBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
}))

const UploadProgressIndicatorBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}))

type AddPortfolioDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess: (portfolio: Portfolio) => void
  portfolio?: Portfolio
}

export default function AddPortfolioDialog({
  open,
  onClose,
  onSuccess,
  portfolio,
}: AddPortfolioDialogProps) {
  const initialFormValues = {
    title: '',
    description: '',
    media: null,
    url: '',
    type: PortfolioType.Media,
    invalid_title: false,
    invalid_description: false,
    invalid_media: false,
    invalid_url: false,
  }
  const [formValues, setFormValues] = React.useState(initialFormValues)
  const [isMediaUploading, setIsMediaUploading] = React.useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = React.useState<number>(0)
  const { addAlert } = useSnackbars()

  React.useEffect(() => {
    if (open && portfolio) {
      setFormValues({
        ...initialFormValues,
        type: portfolio?.type as PortfolioType,
        title: portfolio?.title as string,
        description: portfolio?.description as string,
        media: portfolio?.media as any,
        url: portfolio?.url as string,
      })
    }
  }, [open])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
      [`invalid_${event.target.name}`]: false,
    })
  }

  const handleChangeSelect = (event: SelectChangeEvent<string>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
      invalid_title: false,
      invalid_description: false,
      invalid_url: false,
    })
  }

  const handleChangeMedia = async (event: any) => {
    const formData = new FormData()
    formData.append('thumbnail', false.toString())
    formData.append('file', event.target.files[0])
    try {
      setIsMediaUploading(true)
      const { data } = await uploadMedia(formData, (progress: any) => {
        setUploadProgress(progress)
      })
      setFormValues({
        ...formValues,
        media: data,
      })
      setIsMediaUploading(false)
      setUploadProgress(0)
    } catch (error: any) {
      setIsMediaUploading(false)
      addAlert({
        message: getApiErrorMsg(error) || 'Error Uploading Media',
        type: SnackbarType.Error,
      })
    }
  }

  const handleClickAdd = () => {
    switch (formValues.type) {
      case PortfolioType.Url:
        handleAddPortfolioTypeUrl()
        break
      case PortfolioType.Media:
        handleAddPortfolioTypeMedia()
        break
      default:
        break
    }
  }

  const handleAddPortfolioTypeUrl = () => {
    if (
      !formValues.title ||
      !formValues.description ||
      !formValues.url ||
      !isURL(formValues.url)
    ) {
      setFormValues({
        ...formValues,
        invalid_title: !formValues.title,
        invalid_description: !formValues.description,
        invalid_media: !formValues.media,
        invalid_url: !formValues.url || !isURL(formValues.url),
      })
      return
    }

    const payload = {
      title: formValues.title,
      description: formValues.description,
      url: formValues.url,
      media: null,
      type: formValues.type,
    }

    onSuccess(payload)
    handleClose()
  }

  const handleAddPortfolioTypeMedia = () => {
    if (!formValues.title || !formValues.description || !formValues.media) {
      setFormValues({
        ...formValues,
        invalid_title: !formValues.title,
        invalid_description: !formValues.description,
        invalid_media: !formValues.media,
      })
      return
    }

    const payload = {
      title: formValues.title,
      description: formValues.description,
      url: null,
      media: formValues.media,
      type: formValues.type,
    }

    onSuccess(payload)
    handleClose()
  }

  const handleClose = () => {
    onClose()
    setTimeout(() => {
      setFormValues(initialFormValues)
    }, 195)
  }

  const renderFurtherFields = () => {
    switch (formValues.type) {
      case PortfolioType.Url:
        return (
          <StyledTextField
            value={formValues.url}
            onChange={handleChange}
            name="url"
            label="URL"
            fullWidth
            error={formValues.invalid_url}
            helperText={
              formValues.invalid_url
                ? formValues.url.length > 0
                  ? 'Invalid Url'
                  : 'URL is required'
                : ''
            }
          />
        )
      case PortfolioType.Media:
        return (
          <Box>
            <MediaButtonBox>
              <LoadingButton
                variant="contained"
                component="label"
                sx={{ boxShadow: 'none' }}
                loading={isMediaUploading}
              >
                {formValues.media ? 'Change Media' : 'Add Media'}
                <input
                  type="file"
                  hidden
                  value={''}
                  onChange={handleChangeMedia}
                  disabled={isMediaUploading}
                  accept="application/pdf"
                />
              </LoadingButton>
              {isMediaUploading && (
                <UploadProgressIndicatorBox>
                  <CircularProgressWithLabel value={uploadProgress} />
                  <Typography variant="body2">
                    {isMediaUploading && uploadProgress < 100
                      ? 'Uploading...'
                      : 'Processing...'}
                  </Typography>
                </UploadProgressIndicatorBox>
              )}
            </MediaButtonBox>
            {formValues.invalid_media && (
              <Typography color="error" variant="body2" sx={{ marginTop: 1 }}>
                Media is required
              </Typography>
            )}
            {formValues.media && (
              <Typography sx={{ marginTop: 2 }}>
                {(formValues.media as Media).name}
              </Typography>
            )}
          </Box>
        )
      default:
        return null
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{portfolio ? 'Edit' : 'Add'} Portfolio</DialogTitle>
      <DialogContent dividers>
        <StyledFormControl fullWidth>
          <InputLabel id="type-select-label">Type</InputLabel>
          <Select
            labelId="type-select-label"
            value={formValues.type}
            label="Type"
            onChange={handleChangeSelect}
            name="type"
          >
            {Object.values(PortfolioType).map((value) => (
              <MenuItem key={value} value={value}>
                {capitalize(value)}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        <StyledTextField
          value={formValues.title}
          onChange={handleChange}
          name="title"
          label="Title"
          error={formValues.invalid_title}
          helperText={formValues.invalid_title && 'Title is required'}
          fullWidth
        />
        <StyledTextField
          value={formValues.description}
          onChange={handleChange}
          name="description"
          label="Description"
          fullWidth
          rows={4}
          multiline
          error={formValues.invalid_description}
          helperText={
            formValues.invalid_description && 'Description is required'
          }
        />
        {renderFurtherFields()}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleClickAdd}>{portfolio ? 'Save' : 'Add'}</Button>
      </DialogActions>
    </Dialog>
  )
}
