import * as React from 'react'
import Button from '@mui/lab/LoadingButton'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  Divider,
  ListItemText, Alert,
} from '@mui/material'
import { useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import validator from 'validator'
import ReactPhoneInput from 'react-phone-input-material-ui'
import { getApiErrorMsg, getMediaUrl } from '@assembly/utils'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import {
  AvatarUpload,
  CapabilitiesSearchAndSelect, CircularProgressWithLabel,
  TextFieldPhoneInput,
} from '@assembly/components'
import {
  Capability,
  Contractor,
  ContractorFormValuesType, Media,
  Portfolio,
  Skill,
} from '@assembly/types'
import {uploadMedia, uploadPrivateFile} from '@assembly/api/media'
import { updateProfile } from '@assembly/api/contractor'
import { styled } from '@mui/material/styles'
import AddPortfolioDialog from './AddPortfolioDialog'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SkillsSearchAndSelect from '@assembly/components/SkillsSearchAndSelect'
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {baseURL} from "@assembly/config/axios";

const StyledTextField = styled(TextField)(() => ({
  margin: 0,
  marginBottom: '20px',
}))

const StyledReactPhoneInput = styled(ReactPhoneInput)(() => ({
  margin: 0,
  marginBottom: '20px',
}))

const StyledDatePicker = styled(DatePicker)(() => ({
  margin: 0,
  marginBottom: '20px',
  width: '100%',
}))

const StyledFormControl = styled(FormControl)(() => ({
  margin: 0,
  marginBottom: '20px',
  width: '100%',
}))

const Row = styled(Box)(() => ({
  display: 'flex',
  gap: '20px',
}))

const AvatarUploadBox = styled(Box)(() => ({
  marginBottom: '20px',
}))

const SectionLabel = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 500,
  marginBottom: '10px',
}))

const StyledButton = styled(Button)(() => ({
  boxShadow: 'none',
  textTransform: 'none',
}))

const PortfolioSectionBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '10px',
}))

const OneLineTypography = styled(Typography)(() => ({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  width: '80%',
}))

const SkillBox = styled(Box)(() => ({
  marginTop: '20px',
}))

const transitionDuration: number = 200

interface EditProfileDialogTitleProps {
  children?: React.ReactNode
  onClose: () => void
}

function CreateResourceDialogTitle(props: EditProfileDialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface EditProfileDialogProps {
  open: boolean
  onClose: () => void
  onSuccess: (profile: Contractor) => void
  profile?: Contractor
}

export default function EditProfileDialog(props: EditProfileDialogProps) {
  const { open, onClose, profile, onSuccess } = props
  const formInitialValues: ContractorFormValuesType = {
    label: '',
    email: '',
    assemblyEmail: '',
    phone: '',
    firstName: '',
    lastName: '',
    payoutCurrency: 'USD',
    paymentCountry: 'US',
    profileLink: '',
    videoInterviewLink: '',
    minNoticePeriodDays: null,
    capabilities: [],
    skills: [],
    portfolio: [],
    bio: '',
    profilePicture: null,
    availableStartDate: null,
    desiredWeeklyHours: null,
    desiredHourlyRate: null,
    availableWeeklyHours: null,
    w8BEN: null,
    invalid_label: false,
    invalid_email: false,
    invalid_firstName: false,
    invalid_lastName: false,
    invalid_phone: false,
    invalid_availableStartDate: false,
    invalid_assemblyEmail: false,
    invalid_minNoticePeriodDays: false,
    invalid_profileLink: false,
    invalid_profilePicture: false,
    invalid_portfolio: false,
    invalid_w8BEN: false,
  }
  const [formValues, setFormValues] = React.useState(formInitialValues)
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const [isMediaUploading, setIsMediaUploading] = React.useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = React.useState<number>(0)
  const { addAlert } = useSnackbars()
  const [addPortfolioDialogOpen, setAddPortfolioDialogOpen] =
    React.useState<boolean>(false)
  const [selectedPortfolioIndex, setSelectedPortfolioIndex] =
    React.useState<number>(-1)

  React.useEffect(() => {
    if (open) {
      if (profile) {
        setFormValues({
          ...formValues,
          ...profile,
          label: profile.label || '',
          firstName: profile.firstName || '',
          lastName: profile.lastName || '',
          profileLink: profile.profileLink || '',
          bio: profile.bio || '',
          phone: profile.phone || '',
          availableStartDate: profile.availableStartDate
            ? moment(profile.availableStartDate, 'YYYY-MM-DD')
            : null,
        })
      }
    }
  }, [profile, open])

  const handleChangeFormField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleChangeMinNoticePeriodDays = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target
    setFormValues({
      ...formValues,
      minNoticePeriodDays: Number(value),
      availableStartDate: value
        ? moment().add(Number(value) + 1, 'days')
        : null,
      invalid_minNoticePeriodDays: false,
    })
  }

  const handleClickSave = async () => {
    if (
      formValues.label.length === 0 ||
      formValues.firstName.length === 0 ||
      formValues.lastName.length === 0 ||
      !formValues.profileLink ||
      formValues.profileLink?.length === 0 ||
      !validator.isURL(formValues.profileLink) ||
      (formValues.profilePicture && formValues.profilePicture.id === null) ||
      formValues.profilePicture === null ||
      formValues.minNoticePeriodDays === null ||
      formValues.portfolio?.length === 0 ||
      formValues.w8BEN === null
    ) {
      setFormValues({
        ...formValues,
        invalid_label: formValues.label?.length === 0,
        invalid_firstName: formValues.firstName?.length === 0,
        invalid_lastName: formValues.lastName?.length === 0,
        invalid_profileLink:
          !formValues.profileLink ||
          formValues.profileLink?.length === 0 ||
          !validator.isURL(formValues.profileLink),
        invalid_profilePicture:
          (formValues.profilePicture &&
            formValues.profilePicture.id === null) ||
          formValues.profilePicture === null,
        invalid_minNoticePeriodDays: formValues.minNoticePeriodDays === null,
        invalid_portfolio: formValues.portfolio?.length === 0,
        invalid_w8BEN: formValues.w8BEN === null
      })
      return
    }
    setIsSaving(true)
    const payload = {
      profilePicture:
        formValues.profilePicture && formValues.profilePicture.id
          ? formValues.profilePicture
          : null,
      label: formValues.label,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      assemblyEmail: formValues.assemblyEmail,
      phone: formValues.phone,
      payoutCurrency: formValues.payoutCurrency,
      paymentCountry: formValues.paymentCountry,
      capabilities: formValues.capabilities,
      skills: formValues.skills,
      desiredHourlyRate: formValues.desiredHourlyRate,
      desiredWeeklyHours: formValues.desiredWeeklyHours,
      availableWeeklyHours: formValues.availableWeeklyHours,
      profileLink: formValues.profileLink,
      bio: formValues.bio,
      availableStartDate: moment(formValues.availableStartDate).format(
        'YYYY-MM-DD'
      ),
      minNoticePeriodDays: formValues.minNoticePeriodDays,
      portfolio: formValues.portfolio,
      w8BEN: formValues.w8BEN
    }

    try {
      const { data } = await updateProfile({
        id: profile?.id,
        ...payload,
      })
      setIsSaving(false)
      onSuccess(data)
      addAlert({
        message: 'Profile Updated!',
        type: SnackbarType.Success,
      })
      handleClose()
    } catch (error: any) {
      setIsSaving(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const handleClose = () => {
    setTimeout(() => {
      setFormValues(formInitialValues)
    }, transitionDuration)
    setIsSaving(false)
    onClose()
  }

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target
    setFormValues({
      ...formValues,
      [name]: value,
      [`invalid_${name}`]: false,
    })
  }

  const handleChangePhone = (value: any) => {
    setFormValues({
      ...formValues,
      phone: value,
      invalid_phone: false,
    })
  }

  const handleChangeCapabilities = (capabilities: Capability[]) => {
    setFormValues({
      ...formValues,
      capabilities,
    })
  }

  const handleChangeSkills = (skills: Skill[]) => {
    setFormValues({
      ...formValues,
      skills,
    })
  }

  const handleUploadForm = async (event: any) => {
    const formData = new FormData()
    formData.append('thumbnail', false.toString())
    formData.append('file', event.target.files[0])
    try {
      setIsMediaUploading(true)
      const { data } = await uploadPrivateFile(formData)
      setFormValues({
        ...formValues,
        w8BEN: data,
        invalid_w8BEN: false
      })
      setIsMediaUploading(false)
    } catch (error: any) {
      console.error(error)
      setIsMediaUploading(false)
      addAlert({
        message: getApiErrorMsg(error) || 'Error uploading form',
        type: SnackbarType.Error,
      })
    }
  }

  const handleChangeMedia = async (event: any) => {
    const formData = new FormData()
    formData.append('thumbnail', false.toString())
    formData.append('file', event.target.files[0])
    try {
      setIsMediaUploading(true)
      const { data } = await uploadMedia(formData)
      setFormValues({
        ...formValues,
        profilePicture: data,
      })
      setIsMediaUploading(false)
    } catch (error: any) {
      console.error(error)
      setIsMediaUploading(false)
      addAlert({
        message: getApiErrorMsg(error) || 'Error uploading media',
        type: SnackbarType.Error,
      })
    }
  }

  const handleRemoveMedia = () => {
    setFormValues({
      ...formValues,
      profilePicture: null,
    })
  }

  const handleChangeStartDate = (date: any) => {
    setFormValues({
      ...formValues,
      availableStartDate: date,
      invalid_availableStartDate: false,
    })
  }

  const handleClosePortfolioDialog = () => {
    setAddPortfolioDialogOpen(false)
    setSelectedPortfolioIndex(-1)
  }

  const onPortfolioCreated = (portfolio: Portfolio) => {
    setFormValues({
      ...formValues,
      portfolio: [...formValues.portfolio, portfolio],
    })
  }

  const onPortfolioEdited = (portfolio: Portfolio) => {
    const newPortfolio = [...formValues.portfolio]
    newPortfolio[selectedPortfolioIndex] = portfolio
    setFormValues({
      ...formValues,
      portfolio: newPortfolio,
    })
    setSelectedPortfolioIndex(-1)
  }

  const handleClickDeletePortfolio = (index: number) => {
    const newPortfolio = [...formValues.portfolio]
    newPortfolio.splice(index, 1)
    setFormValues({
      ...formValues,
      portfolio: newPortfolio,
    })
  }

  const handleClickEditPortfolio = (index: number) => {
    setSelectedPortfolioIndex(index)
    setAddPortfolioDialogOpen(true)
  }

  const handleOpenAddPortfolioDialog = () => {
    setAddPortfolioDialogOpen(true)
    setFormValues({
      ...formValues,
      invalid_portfolio: false,
    })
  }

  const UploadProgressIndicatorBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  }))

  const MediaButtonBox = styled(Box)(() => ({
    display: 'flex',
    gap: '10px',
  }))

  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="sm"
        fullWidth
        transitionDuration={transitionDuration}
      >
        <CreateResourceDialogTitle onClose={handleClose}>
          Edit Profile
        </CreateResourceDialogTitle>
        <DialogContent dividers={true}>
          <AvatarUploadBox>
            <AvatarUpload
              onChangeMedia={handleChangeMedia}
              isUploading={isMediaUploading}
              src={getMediaUrl(formValues.profilePicture?.media || '')}
              onClearPhoto={handleRemoveMedia}
              error={formValues.invalid_profilePicture}
              errorMessage="Photo is required"
            />
          </AvatarUploadBox>
          <StyledTextField
            value={formValues.label}
            margin="dense"
            label="Describe yourself in one sentence Ex : Marketing Ninja"
            type="text"
            name="label"
            fullWidth
            variant="outlined"
            onChange={handleChangeFormField}
            error={formValues.invalid_label}
            helperText={formValues.invalid_label ? 'Required' : ''}
          />
          <Row>
            <StyledTextField
              value={formValues.firstName}
              margin="dense"
              name="firstName"
              label="First Name"
              type="text"
              fullWidth
              variant="outlined"
              error={formValues.invalid_firstName}
              onChange={handleChangeFormField}
              helperText={formValues.invalid_firstName ? 'Required' : ''}
            />
            <StyledTextField
              value={formValues.lastName}
              margin="dense"
              name="lastName"
              label="Last Name"
              type="text"
              fullWidth
              variant="outlined"
              error={formValues.invalid_lastName}
              onChange={handleChangeFormField}
              helperText={formValues.invalid_lastName ? 'Required' : ''}
            />
          </Row>
          <StyledTextField
            value={formValues.profileLink || ''}
            margin="dense"
            name="profileLink"
            label="Profile / Linkedin Link"
            type="text"
            fullWidth
            variant="outlined"
            onChange={handleChangeFormField}
            error={formValues.invalid_profileLink}
            helperText={
              formValues.invalid_profileLink
                ? formValues.profileLink &&
                  formValues.profileLink.trim().length > 0
                  ? 'Invalid Link'
                  : 'Required'
                : ''
            }
          />
          <StyledTextField
            value={formValues.minNoticePeriodDays || ''}
            margin="dense"
            name="minNoticePeriodDays"
            label="Notice Period (Days)"
            type="number"
            fullWidth
            variant="outlined"
            onChange={handleChangeMinNoticePeriodDays}
            error={formValues.invalid_minNoticePeriodDays}
            helperText={
              formValues.invalid_minNoticePeriodDays
                ? 'Required'
                : 'Add minumum notice period in days at current job'
            }
          />
          <StyledDatePicker
            readOnly
            label="Available Start Date"
            value={formValues.availableStartDate}
            onChange={handleChangeStartDate}
            slotProps={{
              textField: {
                helperText: formValues.invalid_availableStartDate
                  ? 'Invalid Start Date'
                  : 'Add Notice Period Days above to set start date',
                error: formValues.invalid_availableStartDate,
              },
            }}
          />
          <PortfolioSectionBox>
            <Box>
              <SectionLabel>Portfolio</SectionLabel>
              <Typography color="info" variant="body2">
                Ex : Resume, Personal Website, Github, etc
              </Typography>
              {formValues.invalid_portfolio && (
                <Typography color="error" variant="body2">
                  Portfolio is required
                </Typography>
              )}
            </Box>
            <StyledButton
              variant="contained"
              onClick={handleOpenAddPortfolioDialog}
            >
              Add Portfolio
            </StyledButton>
          </PortfolioSectionBox>
          <List
            sx={{ marginBottom: formValues.portfolio.length > 0 ? '20px' : 0 }}
          >
            {formValues.portfolio.map((item, index) => (
              <React.Fragment>
                <ListItem
                  key={index}
                  secondaryAction={
                    <Box>
                      <IconButton
                        onClick={() => handleClickEditPortfolio(index)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleClickDeletePortfolio(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <InsertPhotoIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <OneLineTypography>{item.title}</OneLineTypography>
                    }
                    secondary={item.type}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
          <StyledTextField
            value={formValues.bio || ''}
            margin="dense"
            name="bio"
            label="Bio"
            type="text"
            fullWidth
            onChange={handleChangeFormField}
            multiline
            rows={10}
          />
          <StyledReactPhoneInput
            value={formValues.phone}
            onChange={handleChangePhone}
            component={TextFieldPhoneInput}
            country={'us'}
          />
          <Row>
            <StyledFormControl>
              <InputLabel id="payout-select-label">Payout Currency</InputLabel>
              <Select
                labelId="payout-select-label"
                value={formValues.payoutCurrency}
                label="Payout Currency"
                name="payoutCurrency"
                onChange={handleChangeSelect}
              >
                <MenuItem value="USD">USD</MenuItem>
              </Select>
            </StyledFormControl>
            <StyledFormControl>
              <InputLabel id="pc-select-label">Payment Country</InputLabel>
              <Select
                labelId="pc-select-label"
                value={formValues.paymentCountry}
                label="Payment Country"
                name="paymentCountry"
                onChange={handleChangeSelect}
              >
                <MenuItem value="US">US</MenuItem>
              </Select>
            </StyledFormControl>
          </Row>
          <CapabilitiesSearchAndSelect
            onChange={handleChangeCapabilities}
            value={formValues.capabilities}
          />
          <SkillBox>
            <SkillsSearchAndSelect
              onChange={handleChangeSkills}
              value={formValues.skills}
            />
          </SkillBox>
          <Box marginTop={5}>
            <Alert severity="info" style={{marginBottom: 10}}>
              We are required to collect a W8BEN form for tax reasons.
              <ul>
                <li>Download the form from <a href={"https://www.irs.gov/pub/irs-pdf/fw8ben.pdf"} target={"_blank"}>here</a> </li>
                <li>Fill the form sections <b>1, 2, 3, 4, 6a & 8</b> and then <b>sign and date at the bottom</b></li>
                <li>Please review this <a href={"https://www.youtube.com/watch?v=nLy-cBcLNIE"} target={"_blank"}>video</a> on how to fill this form</li>
              </ul>
            </Alert>
            <MediaButtonBox>
              <LoadingButton
                  variant="contained"
                  component="label"
                  sx={{ boxShadow: 'none' }}
                  loading={isMediaUploading}
              >
                {formValues.w8BEN ? 'Change W8BEN Form' : 'Add W8BEN Form'}
                <input
                    type="file"
                    hidden
                    value={''}
                    onChange={handleUploadForm}
                    disabled={isMediaUploading}
                    accept="application/pdf"
                />
              </LoadingButton>
              {isMediaUploading && (
                  <UploadProgressIndicatorBox>
                    <CircularProgressWithLabel value={uploadProgress} />
                    <Typography variant="body2">
                      {isMediaUploading && uploadProgress < 100
                          ? 'Uploading...'
                          : 'Processing...'}
                    </Typography>
                  </UploadProgressIndicatorBox>
              )}
            </MediaButtonBox>
            {formValues.invalid_w8BEN && (
                <Typography color="error" variant="body2" sx={{ marginTop: 1 }}>
                  W8BEN Form is required
                </Typography>
            )}
            {formValues.w8BEN && (
                <div style={{marginTop: 5}}>
                  <a href={baseURL + "/media/private/resource/file/" + (formValues.w8BEN as Media).id} target="_blank"> Download form </a>
                </div>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button loading={isSaving} onClick={handleClickSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <AddPortfolioDialog
        open={addPortfolioDialogOpen}
        onClose={handleClosePortfolioDialog}
        onSuccess={
          selectedPortfolioIndex !== -1 ? onPortfolioEdited : onPortfolioCreated
        }
        portfolio={formValues.portfolio[selectedPortfolioIndex]}
      />
    </div>
  )
}
