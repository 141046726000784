import { styled } from '@mui/material/styles'
import {
  Box,
  Typography,
  Avatar,
  Divider,
  LinearProgress,
  linearProgressClasses,
  Tooltip,
  CardActionArea,
} from '@mui/material'
import { Resource } from '@assembly/types/resource'
import { formatNumbersWithCommas, getMediaUrl } from '@assembly/utils'
import { Allocation } from '@assembly/types'
import { capitalize } from 'lodash'

const UserCardBanner = styled(Box)(({ theme }) => ({
  height: '88.06px',
  borderTopLeftRadius: '24.461px',
  borderTopRightRadius: '24.461px',
  display: 'flex',
  justifyContent: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  background: '#505acd4d',
  [theme.breakpoints.down('lg')]: {
    height: '68px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '64px',
    borderTopLeftRadius: '16.139px',
    borderTopRightRadius: '16.139px',
  },
}))

const UserCardProfile = styled(Avatar)(({ theme }) => ({
  width: '110.425px',
  height: '110.425px',
  marginTop: '23px',
  [theme.breakpoints.down('lg')]: {
    width: '80px',
    height: '80px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '72px',
    height: '72px',
  },
}))

const UserName = styled(Typography)(({ theme }) => ({
  fontSize: '20.967px',
  fontWeight: 600,
  fontFamily: 'Space Grotesk',
  textAlign: 'center',
  marginTop: '50px',
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  [theme.breakpoints.down('lg')]: {
    fontSize: '18px',
    marginTop: '40px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '13.833px',
    marginTop: '35px',
  },
}))

const UserLabel = styled(Typography)(({ theme }) => ({
  fontSize: '13.279px',
  fontWeight: 300,
  fontFamily: 'Space Grotesk',
  textAlign: 'center',
  marginTop: '-2px',
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  paddingLeft: '5px',
  paddingRight: '5px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '12px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '8.761px',
  },
}))

const UserCardDivider = styled(Divider)(({ theme }) => ({
  marginTop: '10px',
  marginBottom: '10px',
  marginLeft: '17px',
  marginRight: '17px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '5px',
    marginTop: '5px',
    marginLeft: '11.3px',
    marginRight: '11.3px',
  },
}))

const InfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginLeft: '17px',
  marginRight: '17px',
  marginBottom: '5px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '2px',
    marginLeft: '11.3px',
    marginRight: '11.3px',
  },
}))

const InfoLabel = styled(Typography)(({ theme }) => ({
  fontSize: '10.483px',
  fontWeight: 300,
  fontFamily: 'Space Grotesk',
  marginRight: '10px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '6.971px',
  },
}))

const InfoValue = styled(Typography)(({ theme }) => ({
  fontSize: '10.483px',
  fontWeight: 600,
  fontFamily: 'Space Grotesk',
  [theme.breakpoints.down('sm')]: {
    fontSize: '6.971px',
  },
}))

const TotalBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#F3F3F3',
  borderBottomLeftRadius: '24.461px',
  borderBottomRightRadius: '24.461px',
  justifyContent: 'space-between',
  paddingTop: '14.5px',
  paddingBottom: '14.5px',
  width: '100%',
  boxSizing: 'border-box',
  paddingLeft: '17.08px',
  paddingRight: '17.08px',
  [theme.breakpoints.down('lg')]: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  [theme.breakpoints.down('sm')]: {
    borderBottomLeftRadius: '16.139px',
    borderBottomRightRadius: '16.139px',
    paddingLeft: '11.3px',
    paddingRight: '11.3px',
  },
}))

const TotalLabel = styled(Typography)(({ theme }) => ({
  fontSize: '10.483px',
  fontWeight: 600,
  fontFamily: 'Space Grotesk',
  [theme.breakpoints.down('sm')]: {
    fontSize: '6.917px',
  },
}))

const TotalValue = styled(Typography)(({ theme }) => ({
  fontSize: '17.472px',
  fontWeight: 600,
  fontFamily: 'Space Grotesk',
  [theme.breakpoints.down('lg')]: {
    fontSize: '15px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '11.528px',
  },
}))

const UserCardBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  boxSizing: 'border-box',
}))

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '6.989px',
  borderRadius: 5,
  border: '1.398px solid #D5D5D5',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E8E8E9',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
  [theme.breakpoints.down('sm')]: {
    height: '4.611px',
  },
}))

const ProgressBarBox = styled(Box)(({ theme }) => ({
  paddingLeft: '17.08px',
  paddingRight: '17.08px',
  marginBottom: '22.38px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '11.38px',
    paddingLeft: '11.3px',
    paddingRight: '11.3px',
  },
}))

const ProgressBarTitle = styled(Typography)(({ theme }) => ({
  fontSize: '10.483px',
  fontFamily: 'Space Grotesk',
  fontWeight: 600,
  marginBottom: '5.06px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '6.917px',
  },
}))

const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  '.MuiCardActionArea-focusHighlight': {
    borderRadius: '25px',
  },
  '&:hover': {
    borderRadius: '25px',
  },
  borderRadius: '25px',
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    borderRadius: '17px',
    '.MuiCardActionArea-focusHighlight': {
      borderRadius: '17px',
    },
    '&:hover': {
      borderRadius: '17px',
    },
  },
}))

export const UserCard = ({
  person,
  onClick,
}: {
  person: Allocation
  onClick: (resource: Resource) => void
}) => {
  const getProgress = () => {
    return (Number(person.rate) / 180) * 100
  }

  const skilsText = person.resource?.capabilities.map((c) => c.name).join(', ')

  return (
    <UserCardBox>
      <StyledCardActionArea
        onClick={() => onClick(person.resource as Resource)}
      >
        <Box>
          <UserCardBanner>
            <UserCardProfile
              src={getMediaUrl(person.resource?.profilePicture?.media || '')}
              sx={{ bgcolor: 'primary.main' }}
            />
          </UserCardBanner>
          <UserName>
            {person?.resource?.firstName + ' ' + person?.resource?.lastName}
          </UserName>
          <UserLabel>{person.resource?.label}</UserLabel>
          <UserCardDivider />
          <InfoBox>
            <InfoLabel>Years of Experience:</InfoLabel>
            <InfoValue>5</InfoValue>
          </InfoBox>
          <InfoBox>
            <InfoLabel>Skills:</InfoLabel>
            <Tooltip title={skilsText}>
              <InfoValue noWrap>{skilsText}</InfoValue>
            </Tooltip>
          </InfoBox>
          <InfoBox>
            <InfoLabel>Hourly Rate:</InfoLabel>
            <InfoValue>
              ${formatNumbersWithCommas(person.amount as number)}
            </InfoValue>
          </InfoBox>
          <UserCardDivider />
        </Box>
        <ProgressBarBox>
          <ProgressBarTitle>
            {person.rate} {capitalize(person.rateUnit)}/
            {capitalize(person.frequency)}
          </ProgressBarTitle>
          <BorderLinearProgress variant="determinate" value={getProgress()} />
        </ProgressBarBox>
        <TotalBox>
          <TotalLabel>Total:</TotalLabel>
          <TotalValue>
            ${formatNumbersWithCommas(person.amount as number)}
          </TotalValue>
        </TotalBox>
      </StyledCardActionArea>
    </UserCardBox>
  )
}
