import {
  acceptAssemblyStartDate,
  getAssemblies,
} from '@assembly/api/contractor'
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  CircularProgress,
  colors,
  Alert,
} from '@mui/material'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import { styled } from '@mui/material/styles'
import { LinkButton } from '@assembly/components'
import moment from 'moment'
import {capitalize, toLower} from 'lodash'
import { getApiErrorMsg } from '@assembly/utils'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { useIntercom } from 'react-use-intercom'
import {AllocationStatus, Assembly, Status} from '@assembly/types'

const StyledAccordion = styled(Accordion)(() => ({
  border: '1px solid',
  borderColor: colors.grey[200],
  boxShadow: 'none',
  marginBottom: '15px',
  '&:before': {
    display: 'none',
  },
}))

const Name = styled(Typography)(() => ({
  fontSize: '25px',
  fontWeight: 500,
}))

const InfoItemBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '15px 0 10px 0',
}))

const InfoLabel = styled(Typography)(() => ({
  fontFamily: 'Space Grotesk',
}))

const InfoValue = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: '18px',
}))

const ProjectLinkBtnBox = styled(Box)(() => ({
  marginTop: '15px',
  display: 'flex',
  justifyContent: 'space-between',
}))

const ErrorBox = styled(Box)(() => ({
  background: '#ffffff',
  padding: '10px',
}))

const AcceptStartDateBtn = styled(LoadingButton)(() => ({
  boxShadow: 'none',
  textTransform: 'none',
}))

const AcceptStartDateAlert = styled(Alert)(() => ({
  marginTop: '15px',
}))

const BtnsBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
}))

export default function Assemblies() {
  const { store, setAssemblies } = useAppStore()
  const { addAlert } = useSnackbars()
  const didMountRef = React.useRef(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    if (!didMountRef.current) {
      if (!store.isAssembliesLoaded) {
        getAssembliesAsync()
      } else {
        setIsLoading(false)
      }
      didMountRef.current = true
    }
  }, [])

  const getAssembliesAsync = async () => {
    try {
      setIsLoading(true)
      const { data } = await getAssemblies()
      setAssemblies(data)
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  if (!isLoading && store.assemblies.length === 0) {
    return (
      <ErrorBox>
        <Typography>No assemblies found</Typography>
      </ErrorBox>
    )
  }

  return (
    <Box>
      {store.assemblies.map((assembly, index) => (
        <AssemblyCard key={index} assembly={assembly} />
      ))}
    </Box>
  )
}

const AssemblyCard = ({ assembly }: { assembly: Assembly }) => {
  const { showNewMessage } = useIntercom()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [acceptStartDateSuccessMsg, setAcceptStartDateSuccessMsg] =
    React.useState<string>('')
  const { addAlert } = useSnackbars()

  const handleAcceptStartDate = async () => {
    try {
      setIsLoading(true)
      const { data } = await acceptAssemblyStartDate(assembly.assemblyID)
      if (data.message) {
        setAcceptStartDateSuccessMsg(data.message)
      }

      if (data.messages) {
        setAcceptStartDateSuccessMsg(data.messages.join(', '))
      }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  const handleClickRequestChange = () => {
    showNewMessage(
      'I’m unable to start on this date, Can you please change it to ..'
    )
  }

  return (
    <StyledAccordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Name>{assembly.assemblyName}</Name>
      </AccordionSummary>
      <AccordionDetails>
        <InfoItemBox>
          <InfoLabel>Start Date</InfoLabel>
          <InfoValue>
            {moment(assembly.assemblyStartDate, 'YYYY-MM-DD').format(
              'MMM DD, YYYY'
            )}
          </InfoValue>
        </InfoItemBox>
        <Divider />
        <InfoItemBox>
          <InfoLabel>Contract Details</InfoLabel>
          <InfoValue>
            {toLower(assembly.contractDetails)}
          </InfoValue>
        </InfoItemBox>
        <Divider />
        <InfoItemBox>
          <InfoLabel>Contract Statement of Work</InfoLabel>
          <InfoValue>
            {assembly.sowLink ? <a href={assembly.sowLink} target="_blank"> Link </a> : 'Standard Role Requirements'}
          </InfoValue>
        </InfoItemBox>
        <Divider />
        <InfoItemBox>
          <InfoLabel>Status</InfoLabel>
          <InfoValue>{capitalize(assembly.assemblyStatus)}</InfoValue>
        </InfoItemBox>
        <Divider />
        <InfoItemBox>
          <InfoLabel>Project Management Board</InfoLabel>
          <InfoValue>
            {assembly.assemblyProjectManagementLink ? <a href={assembly.assemblyProjectManagementLink} target="_blank"> Link </a> : 'Not setup yet'}
          </InfoValue>
        </InfoItemBox>
        {assembly.allocationStatus === AllocationStatus.Ready && (
          <AcceptStartDateAlert severity="info">
            Start Date Accepted ! Look out for an email from us with next steps !
          </AcceptStartDateAlert>
        )}
        <Divider />
        {assembly.assemblyStatus === Status.Provisioning && assembly.allocationStatus === AllocationStatus.Approved &&
            !acceptStartDateSuccessMsg && (
                <AcceptStartDateAlert severity="info">
                  By accepting the Contract Compensation Details and Start date you hereby confirm that you will be
                  available to start on this date with Assembly Industries in contract with the client.
                  You are also confirming that you have read and agree to the terms and conditions of the contract stipulated in the
                  Contract Details <a href={"/contract"} target="_blank">here</a>.
                </AcceptStartDateAlert>
            )}
        <ProjectLinkBtnBox>
          <BtnsBox>
            {assembly.assemblyStatus === Status.Provisioning && assembly.allocationStatus === AllocationStatus.Approved && (
              <React.Fragment>
                {!acceptStartDateSuccessMsg && (
                  <AcceptStartDateBtn
                    loading={isLoading}
                    onClick={handleAcceptStartDate}
                    variant="contained"
                    color="success"
                  >
                    Accept Contract and Start Date
                  </AcceptStartDateBtn>
                )}
                <AcceptStartDateBtn
                  variant="contained"
                  onClick={handleClickRequestChange}
                >
                  Request Change
                </AcceptStartDateBtn>
              </React.Fragment>
            )}
          </BtnsBox>
        </ProjectLinkBtnBox>
        {acceptStartDateSuccessMsg && (
          <AcceptStartDateAlert severity="success">
            {acceptStartDateSuccessMsg}
          </AcceptStartDateAlert>
        )}
      </AccordionDetails>
    </StyledAccordion>
  )
}
