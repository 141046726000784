import { getPayoutEntries } from '@assembly/api/contractor'
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'
import { capitalize } from 'lodash'
import { useAppStore, useSnackbars } from '@assembly/hooks'
import { SnackbarType } from '@assembly/contexts'
import { getApiErrorMsg } from '@assembly/utils'

const PayoutEntriesBox = styled(Box)(() => ({
  background: '#FFFFFF',
  borderRadius: '10px',
}))

const NoDataBox = styled(Box)(() => ({
  height: '200px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export default function PayoutEntries() {
  const [loading, setLoading] = React.useState(false)
  const { store, setPayoutEntries } = useAppStore()
  const didMountRef = React.useRef(false)
  const { addAlert } = useSnackbars()

  React.useEffect(() => {
    if (!didMountRef.current) {
      if (!store.isPayoutEntriesLoaded) {
        getPayoutEntriesAsync()
      }
      didMountRef.current = true
    }
  }, [])

  const getPayoutEntriesAsync = async () => {
    try {
      setLoading(true)
      const { data } = await getPayoutEntries()
      setPayoutEntries(data)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box>
      <PayoutEntriesBox>
        <Typography variant="h5" fontWeight={500}>
          Payout Entries
        </Typography>
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Billable Amount</TableCell>
                <TableCell>Billable Units</TableCell>
                <TableCell>Billing Start</TableCell>
                <TableCell>Billing End</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {store.payoutEntries.length === 0 && (
                <TableCell colSpan={8} rowSpan={8}>
                  <NoDataBox>
                    <Typography>No data</Typography>
                  </NoDataBox>
                </TableCell>
              )}
              {store.payoutEntries.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    ${row.billableAmount}
                  </TableCell>
                  <TableCell>{row.billableUnits}</TableCell>
                  <TableCell>{row.billingStart}</TableCell>
                  <TableCell>{row.billingEnd}</TableCell>
                  <TableCell>{capitalize(row.status)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PayoutEntriesBox>
    </Box>
  )
}
