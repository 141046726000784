import { getPublicProfile } from '@assembly/api/contractor'
import { SnackbarType } from '@assembly/contexts'
import { useSnackbars } from '@assembly/hooks'
import { Contractor } from '@assembly/types'
import { getApiErrorMsg } from '@assembly/utils'
import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { ContractorPublicProfile } from '@assembly/components'

const LoadingBox = styled(Box)(() => ({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export default function PublicProfile() {
  const params = useParams()
  const { addAlert } = useSnackbars()
  const didMountRef = React.useRef<boolean>(false)
  const [data, setData] = React.useState<Contractor>()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<string>('')

  React.useEffect(() => {
    if (!didMountRef.current) {
      getProfileAsync()
      didMountRef.current = true
    }
  }, [])

  const getProfileAsync = async () => {
    try {
      setIsLoading(true)
      const { data } = await getPublicProfile(params.id as string)
      setData(data)
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      setError(getApiErrorMsg(error))
      addAlert({
        message: getApiErrorMsg(error),
        type: SnackbarType.Error,
      })
    }
  }

  if (isLoading) {
    return (
      <LoadingBox>
        <CircularProgress />
      </LoadingBox>
    )
  }

  if (error || !data) {
    return (
      <LoadingBox>
        <Typography variant="h4" color="error">
          {error}
        </Typography>
      </LoadingBox>
    )
  }

  return <ContractorPublicProfile data={data} />
}
