import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Alert,
  AlertColor,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'

const QuestionText = styled(Typography)(() => ({
  marginBottom: '10px',
  fontSize: '18px',
}))

const StyledFormControl = styled(FormControl)(() => ({
  marginLeft: '10px',
}))

type QuestionDialogProps = {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  challengeQuestions: any[]
}

export default function QuestionDialog({
  open,
  onClose,
  onSuccess,
  challengeQuestions,
}: QuestionDialogProps) {
  const [questionAnswer, setQuestionAnswer] = React.useState<{
    [key: string]: {
      answer: string | null
      alert: boolean
      msg: string | null
      severity: string | null
      index: number
    }
  }>({})
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (open && challengeQuestions) {
      setQuestionAnswer(
        challengeQuestions.reduce((acc, _, index) => {
          return {
            ...acc,
            [`question_${index}`]: {
              answer: null,
              alert: false,
              index,
            },
          }
        }, {})
      )
    }
  }, [open])

  const handleChangeAnswer = (event: any) => {
    setQuestionAnswer({
      ...questionAnswer,
      [event.target.name]: {
        ...questionAnswer[event.target.name],
        answer: event.target.value,
        invalidAnswer: false,
      },
    })
  }

  const handleClickDone = () => {
    setIsLoading(true)
    let isInvalidState = false
    challengeQuestions.forEach((question, index) => {
      const key = `question_${index}`

      if (questionAnswer[key].answer === null) {
        questionAnswer[key].alert = true
        questionAnswer[key].msg = 'Answer is required.'
        questionAnswer[key].severity = 'info'

        isInvalidState = true
      } else if (question.answer !== questionAnswer[key].answer) {
        questionAnswer[key].alert = true
        questionAnswer[key].severity = 'error'
        questionAnswer[key].msg =
          'Answer is incorrect. Please read the content carefully and then try again.'

        isInvalidState = true
      } else if (question.answer === questionAnswer[key].answer) {
        questionAnswer[key].alert = true
        questionAnswer[key].msg = 'Answer is correct.'
        questionAnswer[key].severity = 'success'
      }
    })

    setQuestionAnswer({ ...questionAnswer })

    if (!isInvalidState) {
      setTimeout(() => {
        onClose()
        onSuccess()
        setIsLoading(false)
      }, 2000)
    } else {
      setIsLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Answer the following question to move forward</DialogTitle>
      <DialogContent dividers>
        {challengeQuestions.map((question, index) => {
          const key = `question_${index}`
          return (
            <Box sx={{ marginBottom: '20px' }} key={index}>
              <QuestionText>
                <b>Q{index + 1}.</b> {question.question}
              </QuestionText>
              <StyledFormControl>
                <RadioGroup
                  name={`question_${index}`}
                  onChange={handleChangeAnswer}
                >
                  {question.choices.map((choice: any, index: number) => (
                    <FormControlLabel
                      key={index}
                      value={choice}
                      control={<Radio />}
                      label={choice}
                    />
                  ))}
                </RadioGroup>
              </StyledFormControl>
              {questionAnswer[key] && questionAnswer[key].alert && (
                <Alert
                  severity={questionAnswer[key].severity as AlertColor}
                  sx={{ marginTop: '10px' }}
                >
                  {questionAnswer[key].msg}
                </Alert>
              )}
            </Box>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton onClick={handleClickDone} loading={isLoading}>
          Done
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
