import { Avatar, Box, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import { theme } from '@assembly/theme'

type AvatarUploadProps = {
  src?: string
  onChangeMedia: (event: any) => void
  isUploading: boolean
  onClearPhoto: () => void
  forLogo?: boolean
  error?: boolean
  errorMessage?: string
}

export default function AvatarUpload({
  src,
  onChangeMedia,
  isUploading,
  onClearPhoto,
  forLogo,
  error,
  errorMessage,
}: AvatarUploadProps) {
  const label = forLogo ? 'Logo' : 'Photo'
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Avatar
        sx={{ width: 100, height: 100, bgcolor: theme.palette.primary.main }}
        src={src}
      >
        {forLogo && <CorporateFareIcon sx={{ fontSize: 50 }} />}
      </Avatar>
      <LoadingButton
        sx={{ marginTop: 1 }}
        component={src ? 'button' : 'label'}
        loading={isUploading}
        onClick={src ? onClearPhoto : () => {}}
      >
        {src ? `Remove ${label}` : `Upload ${label}`}
        <input
          type="file"
          hidden
          value={''}
          onChange={onChangeMedia}
          disabled={isUploading}
          accept="image/*"
        />
      </LoadingButton>
      {error && (
        <Typography color="error" variant="body2">
          {errorMessage}
        </Typography>
      )}
    </Box>
  )
}
