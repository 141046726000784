import React from 'react'

export default function PartnerOnboarding() {
  return (
    <React.Fragment>
      <DocumentViewer />
    </React.Fragment>
  )
}

const DocumentViewer = () => {
  return (
    <div style={{ height: '85vh' }}>
      <iframe
        style={{ border: 'none' }}
        width="100%"
        height="100%"
        src="https://docs.google.com/document/u/2/d/e/2PACX-1vTpWpv2mz7G3wUGoHcKeV0jdzGP0T9xnUJGgSuige39hoED8qKYS21ktzM4VVfDtt7WXAlYvJe3mWlC/pub"
      ></iframe>
    </div>
  )
}
